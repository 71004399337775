import React, { useEffect, useLayoutEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import EventService from "../../Services/event-service";
import RaceDetails from "../RaceDetails/race.details.screen";
import EventDetailsSeriesList from "./Components/event.details.series.list";
import MainMenu from "../../Components/Main-Menu/menu.main";
import EventDetailsHeader from "./Components/event.details.header";
import WideViewTable from "../WideView/components/wideView.table";
import SeriesDetails from "./Components/event.series.detail";
import EventResultsFleetWrapper from "../EventResults/Components/event.results.fleet.wrapper";
import EventResultsFleetList from "../EventResults/Components/event.results.fleet.list";

// const MainMenu = React.lazy(() =>
//   import("../../Components/Main-Menu/menu.main")
// );
// const EventDetailsHeader = React.lazy(() =>
//   import("../EventDetails/Components/event.details.header")
// );
const EventDetails = ({ onBack }) => {
  const [event, setEvent] = useState();
  const [series, setSeries] = useState();
  const { eventId, calendarDate, seriesId, raceId, fleetId } = useParams();
  const [cameFromSearch, setCameFromSearch] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  useLayoutEffect(() => {
    if (!eventId || !calendarDate) return;
    getEventDetails();
  }, [eventId, calendarDate]);

  useEffect(() => {
    if (!seriesId || !series) return;
    setTimeout(() => {
      // is seriesId the first series in the list?
      var firstSeriesId = series[0].id;
      if (seriesId == firstSeriesId) return;

      var serieDiv = document.querySelector(`#series-${seriesId}`);
      serieDiv?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "center",
      });
    }, 10);
  }, [seriesId, raceId, series]);

  useEffect(() => {
    if (!location.state) return;
    setCameFromSearch(location.state?.isSearch);
  }, [JSON.stringify(location.state)]);

  const getEventDetails = () => {
    EventService.getEventDetailsByDate(eventId, calendarDate)
      .then((response) => {
        setEvent(response.data.eventData);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onBackButton = () => {
    //console.log(cameFromSearch);
    if (seriesId && raceId) {
      navigate(`/event/${fleetId}`);
    } else {
      if (cameFromSearch) {
        navigate("/search");
        return;
      } else {
        //go back to last page path in the history
        navigate(-1);
      }
      navigate("/");
    }
  };

  return (
    <>
      <MainMenu showBackButon={true} onBackButton={onBackButton} />
      <div className="main-panel">
        <div className="calendar-menu col-12">
          <div className="calendar-menu-title-container">
            <i className="fas fa-list fa-2x me-2" />
            <div className="calendar-menu-title">Event Details</div>
          </div>
        </div>
        <EventDetailsHeader event={event} series={series} />
        <EventResultsFleetList
          eventId={event?.Id}
          fleets={event?.Fleets}
          date={calendarDate}
        />
      </div>
    </>
  );
};

export default EventDetails;
