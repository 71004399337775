import React, { useEffect, useState } from "react";

const MyEventsHeader = ({ onFilterSelected, selectedFilter }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    // if #news-category-list has show class, add event listener to close it when clicking outside

    document.addEventListener("click", (e) => {
      if (
        show &&
        //and target id not equal to news-category-list or their children
        e.target.id != "news-category-list" &&
        e.target.id != "news-filter-button"
      )
        setShow(false);
    });

    return () => {
      document.addEventListener("click", (e) => {
        if (
          show &&
          e.target.id != "news-category-list" &&
          e.target.id != "news-filter-button"
        )
          setShow(false);
      });
    };
  }, [show]);

  return (
    <div className="calendar-menu col-12 d-flex justify-content-between align-items-center">
      <div className="calendar-menu-title-container">
        <i className="fas fa-calendar-alt fa-2x me-2" />
        <div className="calendar-menu-title">My Events</div>
      </div>
    </div>
  );
};

export default MyEventsHeader;
