import Cookies from 'js-cookie';

export default class Cookie {
    static get(name, defaultValue) {
        const val = Cookies.get(name);
        if (val)
            return val;
        else
            return defaultValue;
    }

    static set(name, value, expireDays = 999) {
         Cookies.set(name, value, { expires: expireDays });
    }

    static clear(name) {
        Cookies.remove(name)
    }
}