import React, { useState } from "react";
import moment from "moment";

const MyEventsDatesSelector = ({ dates, setDates, readOnly = false }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const fDates = dates?.map((date) => moment(date).format("YYYY-MM-DD"));

  const handleAddDate = () => {
    var _sdMoment = moment(selectedDate).format("D-MMM-YYYY");
    if (selectedDate && !fDates.includes(selectedDate)) {
      setDates([...dates, selectedDate]);
      setSelectedDate(null);
    }
  };

  const handleDeleteDate = (dateToRemove) => {
    const updatedDates = dates.filter((date) => date !== dateToRemove);
    setDates(updatedDates);
  };

  const fDate = () => {
    return `${selectedDate}`;
  };
  return (
    <>
      <div className="col-12 px-1 mb-3">
        <div className="col-12 fw-bold mb-2">Dates</div>
        <div className="col-12 px-2 d-flex align-items-center">
          <input
            type="date"
            value={selectedDate || ""}
            onChange={(e) => setSelectedDate(e.target.value)}
            disabled={readOnly}
          />
          <div className="ms-2">
            <i
              role="button"
              onClick={handleAddDate}
              disabled={!selectedDate || fDates.includes(fDate())}
              className={`fas fa-plus-circle fa-2x text-${
                selectedDate && !fDates.includes(fDate()) ? "success" : "muted"
              }`}
            ></i>
          </div>
        </div>
        <div className="col-12 mt-3 px-3">
          {dates
            ?.sort((a, b) => new Date(a) - new Date(b))
            .map((date, index) => (
              <div key={index} className="d-flex align-items-center">
                {!readOnly && (
                  <div
                    role="button"
                    onClick={() => handleDeleteDate(date)}
                    className="me-2"
                  >
                    <i className="fas fa-trash-alt fa-2x text-danger"></i>
                  </div>
                )}

                {moment(date).format("D-MMM-YYYY")}
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default MyEventsDatesSelector;
