import React from "react";
import { useUser } from "../../../Components/Common/context";
const ValidationMessages = (
  key,
  validationOptions,
  errorMessage = null,
  ...props
) => {
  const ctx = React.useContext(useUser);
  var genericReq =
    ctx?.resources.FormRequiredInputFeedback ||
    errorMessage?.required ||
    "This field is required.";
  const maxLengthMessage = (maxLength, customMessage) => {
    if (!maxLength) return "";
    if (!customMessage)
      return (
        ctx?.resources.FormMaxLength.replace("{0}", maxLength) ||
        "Max" + maxLength + " characters"
      );
    return customMessage;
  };
  const minLengthMessage = (minLength, customMessage) => {
    if (!minLength) return "";
    if (!customMessage)
      return (
        ctx?.resources.FormMinLength.replace("{0}", minLength) ||
        "Min " + minLength + " characters"
      );
    return customMessage;
  };
  const patternMessage = (pattern, customMessage) => {
    if (!pattern) return "";
    if (!customMessage) return "Invalid e-mail address";
    return customMessage;
  };
  const messages = {
    required: genericReq,
    maxLength: maxLengthMessage(
      validationOptions?.maxLength,
      errorMessage?.maxLength
    ),
    minLength: minLengthMessage(
      validationOptions?.minLength,
      errorMessage?.minLength
    ),
    min: minLengthMessage(validationOptions?.min, errorMessage?.min),
    pattern: patternMessage(validationOptions?.pattern, errorMessage?.pattern),
    validate: genericReq,
  };
  return messages[key?.type] || key?.message || <></>;
};

export default ValidationMessages;
