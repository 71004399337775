import React, { useState, useEffect, useMemo } from "react";
import MainMenu from "../../Components/Main-Menu/menu.main";
import EventService from "../../Services/event-service";
import CalendarScheduleWrapper from "./schedule-view/calendar.schedule.wrapper";
import { toast } from "react-toastify";
import CalendarFilter from "./Components/calendar.filter";

const CalendarScreen = ({ ...props }) => {
  const [savedFrom, setSavedFrom] = useState(
    localStorage.getItem("SAILINGLIVE_calendarSavedFrom")
  );

  const [from, setFrom] = useState(savedFrom || "future");
  const [events, setEvents] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const pageSize = 50;

  useEffect(() => {
    if (page > 1) setPage(1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [from]);

  useEffect(() => {
    localStorage.setItem("SAILINGLIVE_calendarSavedFrom", from);
    if (page == 0) return;
    getCalendar();
  }, [page, pageSize, from]);

  const getCalendar = () => {
    if (isLoading) return;
    setIsLoading(true);
    EventService.getCalendar(from, page, pageSize)
      .then((r) => {
        setEvents(r.data.events);
      })
      .catch((e) => {
        console.error(e);
        toast.error("Error getting calendar");
      })
      .finally(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 50);
      });
  };

  const handleLoadMore = () => {
    if (isLoading || from === "future") return;
    setPage(page + 1);
  };

  return (
    <>
      <MainMenu showBackButon={false} />
      <div className="main-panel">
        <div className="calendar-menu col-12 d-flex justify-content-between align-items-center">
          <div className="calendar-menu-title-container">
            <i className="fas fa-calendar-alt fa-2x me-2" />
            <div className="calendar-menu-title">Events</div>
          </div>

          <CalendarFilter
            onFilterSelected={(f) => setFrom(f)}
            selectedFilter={from}
          />
        </div>
        {useMemo(
          () => (
            <CalendarScheduleWrapper
              events={events}
              selectedFilter={from}
              onLoadMore={() => handleLoadMore()}
              loading={isLoading}
            />
          ),
          [JSON.stringify(events?.events), from, isLoading]
        )}
      </div>
    </>
  );
};

export default CalendarScreen;
