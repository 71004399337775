import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NewVersionBanner from "./Components/Common/App/new.version.banner.jsx";
import Analytics from "./Components/Common/Analytics/analytics.jsx";
import Search from "./Screens/Search/search.jsx";
import EventDetails from "./Screens/EventDetails/event.details.screen.jsx";
import EventResultsScreen from "./Screens/EventResults/event.results.screen.jsx";
import MainLayout from "./Layouts/Main/layout.main.jsx";
import InstallBanner from "./Components/Common/App/install-banner.js";
import { Routes, Route } from "react-router-dom";
import CalendarScreen from "./Screens/Calendar/calendar.screen.jsx";
import NewsList from "./Screens/News/newslist.screen.jsx";
import NewsView from "./Screens/News/newsview.screen.jsx";
import TrainingScreen from "./Screens/Training/training.list.jsx";
import TrainingView from "./Screens/Training/training.view.jsx";
import LoginScreen from "./Screens/Login/login.screen.jsx";
import FeedbackScreen from "./Screens/Feedback/feedback.screen.jsx";
import { useUser } from "./Components/Common/context.jsx";
import MyEventsScreen from "./Screens/Admin/MyEvents/my.events.screen.jsx";
import MyEventsDetail from "./Screens/Admin/MyEvents/my.events.detail.jsx";
import LogoutScreen from "./Screens/Logout/logout.screen.jsx";
import Util from "./Components/Common/util.jsx";

const App = () => {
  //const [userIsActive, setUserIsActive] = useState(false);
  const [canManage, setCanManage] = useState(false);
  const { user } = useUser();

  useEffect(() => {
    //setUserIsActive(Util.isDefined(localStorage.getItem("token")));
    setCanManage(user.IsAdmin || user.IsOrganisation);
  }, [JSON.stringify(user)]);

  return (
    <>
      <Analytics />
      <InstallBanner />
      <MainLayout>
        <Routes>
          {canManage && (
            <>
              <Route path="/manage/my-events" element={<MyEventsScreen />} />
              <Route
                path="/manage/my-events/new"
                element={<MyEventsDetail />}
              />
              <Route
                path="/manage/my-events/edit/:eventId"
                element={<MyEventsDetail />}
              />
            </>
          )}
          <Route index path="/" element={<CalendarScreen />} />
          <Route path="/home" element={<CalendarScreen />} />
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/feedback" element={<FeedbackScreen />} />
          <Route path="/logout" element={<LogoutScreen />} />
          <Route path="/events" element={<CalendarScreen />} />
          {/* <Route path="/results" element={<Events />} /> */}
          <Route
            path="/event-results/:eventId/:date/:fleetId"
            element={<EventResultsScreen />}
          />
          <Route
            path="/event-results/:eventId/:date/:fleetId/:seriesId"
            element={<EventResultsScreen />}
          />
          <Route path="/news" element={<NewsList />} />
          <Route path="/news/:contentId" element={<NewsView />} />
          <Route path="/training" element={<TrainingScreen />} />
          <Route path="/training/:courseDateId" element={<TrainingView />} />
          <Route
            path="/event-details/:eventId/:calendarDate"
            element={<EventDetails />}
          />
          <Route
            path="/event-details/:fleetId/:seriesId"
            element={<EventDetails />}
          />
          <Route
            path="/event-details/:id/:seriesId/:raceId/:fleetId"
            element={<EventDetails />}
          />
          <Route path="/search" element={<Search />} />
          <Route path="*" element={<CalendarScreen />} />
        </Routes>
        <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
        <NewVersionBanner />
      </MainLayout>
    </>
  );
};

export default App;
