import React, { useState, useEffect } from "react";
import FormTextInput from "../../../Components/Common/Inputs/text.form.input.jsx";
import EventsFiltersModel from "../Models/events.filters.model";
import { useForm } from "react-hook-form";
const EventsSearchFilters = ({
  activeFilter,
  onUpdateFilter,
  onSubmitFilter,
}) => {
  const [filter, setFilter] = useState(new EventsFiltersModel());
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (!activeFilter) return;
    setValue("Name", activeFilter.Name);
    setValue("ClubVenue", activeFilter.ClubVenue);
    setValue("Fleet", activeFilter.Fleet);
    setValue("Date", activeFilter.Date);
    setFilter(activeFilter);
  }, [JSON.stringify(activeFilter)]);
  const onSubmit = (data) => {
    let searchData = {
      tab: "Events",
      filter: data,
    };
    onSubmitFilter(searchData);
  };
  const updateParent = (data) => {
    onUpdateFilter(data);
  };

  const handleChange = (name, value) => {
    let newData = { ...filter, [name]: value };
    setFilter(newData);
    updateParent(newData);
  };
  const disableSubmit = () => {
    if (filter.Name || filter.ClubVenue || filter.Fleet || filter.Date)
      return false;
    return true;
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormTextInput
          name="Name"
          label="Name"
          type="text"
          register={register}
          registerOptions={{
            value: filter.Name,
            onChange: (e) => handleChange("Name", e.target.value),
          }}
          errors={errors}
          groupClassName="form-control text-start"
          labelClasses={"fw-bold"}
          inline
        />
        <FormTextInput
          name="ClubVenue"
          label="Club / Venue"
          type="text"
          register={register}
          registerOptions={{
            value: filter.ClubVenue,
            onChange: (e) => handleChange("ClubVenue", e.target.value),
          }}
          errors={errors}
          groupClassName="form-control text-start"
          labelClasses={"fw-bold"}
          inline
        />
        <FormTextInput
          name="Fleet"
          label="Class"
          type="text"
          register={register}
          registerOptions={{
            value: filter.Fleet,
            onChange: (e) => handleChange("Fleet", e.target.value),
          }}
          errors={errors}
          groupClassName="form-control text-start"
          labelClasses={"fw-bold"}
          inline
        />
        <FormTextInput
          name="Date"
          label="Date"
          type="date"
          register={register}
          registerOptions={{
            value: filter.Date,
            onChange: (e) => handleChange("Date", e.target.value),
          }}
          errors={errors}
          groupClassName="form-control text-start"
          labelClasses={"fw-bold"}
          inline
        />
        <div className="col-12 d-flex  my-4">
          <div className="col-4 ms-1 me-2"></div>
          <button
            type="submit"
            className="btn btn-dark searchBtn"
            disabled={disableSubmit()}
          >
            Search Events
          </button>
        </div>
      </form>
    </>
  );
};
export default EventsSearchFilters;
