import moment from "moment";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const TrainingCourseHeader = ({ course, ...props }) => {
  if (!course) return <></>;
  return (
    <>
      <div className="courseDetailsHeader">
        <div className="col-12">
          <div className="courseDetailsHeadercourseName">
            {course.CourseName}
          </div>
          <div className="d-flex flex-column align-items-start justify-content-center col-12">
            <div className="courseDetailsHeadercourseDates mt-1">
              <i className="fas fa-calendar ms-1 me-2" />
              {moment(course.CourseStartDate).format("D-MMM")}
            </div>
            <div className="d-flex mt-3">
              <i className="fas fa-fw ms-1 me-2" />
              <div>
                <div className="fw-bold">{course.OrganisationName}</div>
                <div>{course?.County}</div>
                <div>Contact:</div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: course.ContactDetails || "",
                  }}
                ></div>
              </div>
            </div>
            <div
              className={`d-flex mt-3 align-items-center ${
                !course.Cost ? "d-none" : ""
              }`}
            >
              <i className="fas fa-euro-sign ms-1 me-2" />
              Cost: €{course.Cost}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrainingCourseHeader;
