import React, { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroller";
import Util from "../../../Components/Common/util";
import EventListItem from "./events.list.item";
import moment from "moment";

const EventList = ({
  events,
  selectedEventDate,
  isSearch = false,
  ...props
}) => {
  useEffect(() => {
    //Util.scrollToElement("events-list");
  }, [events]);
  const MappedEvents = () => {
    if (!events) return <></>;
    return (
      <div className="EventList">
        {events.map((e, i) => {
          var _date = moment(selectedEventDate).format("YYYY-MM-DD");

          return (
            <EventListItem
              key={i}
              event={e}
              index={i}
              isSearch={isSearch}
              selectedEventDate={moment(_date).isValid() ? _date : null}
            />
          );
        })}
      </div>
    );
  };

  //get date and add 15 days
  const date = new Date();
  date.setDate(date.getDate() + 15);

  return (
    <div className="main-scroll">
      <InfiniteScroll
        loadMore={() => {}}
        hasMore={false}
        initialLoad={false}
        loader={<div className="loader" key={1}></div>}
        useWindow={false}
      >
        <MappedEvents />
      </InfiniteScroll>
    </div>
  );
};

export default EventList;
