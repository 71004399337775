import React, { useState, useEffect } from "react";
import MainMenu from "../../../Components/Main-Menu/menu.main";
import MyEventsHeader from "./Components/my.events.header";
import MyEventsList from "./Components/my.events.list";
import MyEventsDetailForm from "./Components/my.events.detail.form";
import ManageEventService from "../../../Services/manage-event-service";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const MyEventsDetail = () => {
  const navigate = useNavigate();

  const handleSaveEvent = (event) => {
    if (!event.notes) {
      event.notes = "";
    }
    ManageEventService.saveEvent(event)
      .then((response) => {
        toast.success("Event saved successfully");
        navigate("/manage/my-events", { replace: true });
      })
      .catch((error) => {
        toast.error("Error saving event");
      });
  };

  const handleDeleteEvent = (event) => {
    if (!event.notes) {
      event.notes = "";
    }
    ManageEventService.deleteEvent(event)
      .then((response) => {
        toast.success("Event deleted successfully");
        navigate("/manage/my-events");
      })
      .catch((error) => {
        toast.error("Error deleting event");
      });
  };
  return (
    <>
      <MainMenu />
      <div className="main-panel">
        <MyEventsHeader />
        <div className="myEventsWrapper">
          <MyEventsDetailForm
            onSave={(event) => handleSaveEvent(event)}
            onDelete={(event) => handleDeleteEvent(event)}
          />
        </div>
      </div>
    </>
  );
};

export default MyEventsDetail;
