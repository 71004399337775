import React, { useState, useEffect, useMemo } from "react";
import MainMenu from "../../Components/Main-Menu/menu.main";
import { toast } from "react-toastify";
import FeedbackService from "../../Services/feedback-service";
import FormTextInput from "../../Components/Common/Inputs/text.form.input";
import { useForm } from "react-hook-form";
import FormTextareaInput from "../../Components/Common/Inputs/textarea.form.input";

const FeedbackScreen = ({ ...props }) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const handleSendFeedback = (data) => {
    setIsLoading(true);
    data.MailingApi = process.env.REACT_APP_WEB_API_BASEURL;
    FeedbackService.saveFeedback(data)
      .then(() => {
        toast.success("Thank you for your feedback");
        reset();
      })
      .catch((error) => {
        toast.error("Error sending feedback");
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <MainMenu showBackButon={true} />
      <div className="main-panel">
        <div className="px-2">
          {" "}
          <h1 className="green-header">Feedback</h1>
          <div className="alert alert-info">
            <p>
              We are looking for feedback on how we can improve Irish Sailing
              Live.
            </p>
            <p>
              If you have ideas or suggestions for the software, please submit
              them here and if possible, we will try to incorporate your
              suggestions.
            </p>
          </div>
          <form onSubmit={handleSubmit(handleSendFeedback)}>
            <div className="input-fields">
              <FormTextInput
                label="Name"
                name="Name"
                type="text"
                placeholder="Name"
                register={register}
                registerOptions={{
                  required: true,
                }}
                errors={errors}
                groupClassName={"mb-2"}
              />
              <FormTextInput
                label="Email"
                name="Email"
                type="email"
                placeholder="Email"
                register={register}
                registerOptions={{
                  required: true,
                  pattern: {
                    value: /^\S+@\S+\.\S+$/,
                    message: "Invalid email address",
                  },
                }}
                errors={errors}
                groupClassName={"mb-2"}
              />
              <FormTextareaInput
                label="Comment"
                name="Comment"
                placeholder="Comment"
                register={register}
                registerOptions={{
                  required: true,
                }}
                errors={errors}
                rows={5}
                groupClassName={"mb-2"}
              />
            </div>
            <button
              type="submit"
              className="btn btn-sm btn-primary float-end"
              disabled={isLoading}
            >
              Send my feedback
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default FeedbackScreen;
