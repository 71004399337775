import React, { useState, useEffect, useLayoutEffect } from "react";
import WideViewTableHeader from "./wideView.table.header";
import WideViewTableRow from "./wideView.table.row";
import SeriesHeader from "../../../Components/Common/SeriesHeader/series.header";
import RaceList from "../../../Components/Common/RaceList/racelist";

const WideViewTable = ({ event, series, fleetId }) => {
  const [seriesResults, setSeriesResults] = useState();
  useLayoutEffect(() => {
    if (!series) return;
    setSeriesResults(series.SeriesResults);
  }, [JSON.stringify(series)]);

  return (
    <>
      <div className="WideTableWrapper">
        <table className="col-12 table table-striped table-hover  WideTable ">
          <WideViewTableHeader series={series} />
          <tbody>
            {seriesResults?.map((result) => {
              return (
                <WideViewTableRow
                  series={series}
                  key={result.id}
                  result={result}
                  type={series.RaceType}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default WideViewTable;
