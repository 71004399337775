import React from "react";
import Util from "../../../Components/Common/util";

const HandicappedTable = ({ seriesResult, results }) => {
  return (
    <>
      {/* {seriesResult?.crew && (
        <div>
          <strong>Crew</strong>:{` ${seriesResult?.crew}`}
        </div>
      )} */}
      <div className="mt-1">
        <table className="table table-sm table-striped">
          <thead>
            <tr>
              <td>Race</td>
              <td>Rank</td>
              <td>Handicap</td>
              <td>Time</td>
              <td className="pts">Pts</td>
            </tr>
          </thead>
          <tbody>
            {results?.map((result, i) => {
              return (
                <tr key={i}>
                  <td>{result.RaceRank}</td>
                  <td>{result.Rank}</td>
                  <td>
                    Start: {result.InitialRating}
                    <br />
                    Final: {result.FinalRating}
                  </td>
                  <td>
                    Elapsed: {Util.toHHmmss(result.Elapsed)}
                    <br />
                    Corrected: {Util.toHHmmss(result.Corrected)}
                  </td>
                  <td className="pts">{result.PointsFormatted}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default HandicappedTable;
