import moment from "moment";
import React from "react";

export default class Util {
  static undefined(obj) {
    return obj === undefined || obj === null;
  }

  static empty(obj) {
    if (Util.undefined(obj)) return true;
    else if (typeof obj === "string" || Array.isArray(obj))
      return obj.length === 0;
    else return obj.toString().length === 0;
  }

  static filled(obj) {
    return !Util.empty(obj);
  }

  static ajaxCache(enabled) {
    $.ajaxSetup({ cache: enabled });
  }

  static splitString(obj, pos) {
    if (obj == null) {
      return "";
    }
    obj = obj.trim();
    var arr = [];
    let res = obj.split(" ");
    arr.push(res.shift());
    arr.push(res.join(" "));
    if (pos === -1) {
      return arr;
    }
    if (pos < arr.length) {
      return arr[pos];
    }
    return "";
  }

  // number 1, 2, 3... type: 'days', 'seconds', ... (see momentjs )
  static timeout(date, number, type) {
    const future = moment(date).add(number, type);
    const f = moment().isAfter(future);
    return f;
  }

  static setLocalStorage(key, value) {
    if (isNullOrUndefined(key)) {
      return;
    }
    localStorage.removeItem(key);
    if (value != "") {
      localStorage.setItem(key, value);
    }
  }

  static getLocalStorage(key) {
    if (isNullOrUndefined(key)) {
      return null;
    }
    return localStorage.getItem(key);
  }

  static isValidDate(date) {
    if (!date || date === "0001-01-01T00:00:00" || date.toString().length === 0)
      return false;

    return moment(date.toString()).isValid();
  }

  static isToday(date) {
    return (
      moment().startOf("day").diff(moment(date).startOf("day)"), "days") === 0
    );
  }

  static isPast(date) {
    const diff = moment()
      .startOf("day")
      .diff(moment(date ? date : "").startOf("day)"), "days");
    return diff > 0;
  }

  static isAfter(date1, date2) {
    return moment(date1).unix() > moment(date2).unix();
  }
  static nowIsAfter(date) {
    return moment().unix() > moment(date).unix();
  }

  static isFuture(date) {
    const diff = moment()
      .startOf("day")
      .diff(moment(date ? date : "").startOf("day)"), "days");
    return diff < 0;
  }

  static checkVisible(elm, evalType) {
    evalType = evalType || "visible";

    var vpH = $(window).height(), // Viewport Height
      st = $(window).scrollTop(), // Scroll Top
      y = $(elm).offset().top,
      elementHeight = $(elm).height();

    if (evalType === "visible") return y < vpH + st && y > st - elementHeight;
    if (evalType === "above") return y < vpH + st;
  }

  static scrollToElement(id, timeout = 300) {
    if (id) {
      setTimeout(() => {
        var elmnt = document.getElementById(id);
        if (elmnt) {
          if (!Util.checkVisible(elmnt)) elmnt.scrollIntoView();
        }
      }, timeout);
    }
  }

  static validTestId(id) {
    return id.replace(".", "");
  }

  static getErrorMessage(error) {
    let msg = <span>{error.message}</span>;
    if (
      error.response &&
      error.response.data &&
      error.response.data &&
      error.response.data.Message
    ) {
      if (error.response.data.Message) {
        msg = (
          <span>
            <b>{error.response.data.Message}</b>
          </span>
        );
      } else {
        msg = <span>{msg}</span>;
      }
    } else if (!error.message) {
      msg = error.statusText;
    }
    return msg;
  }

  static join(array, field, separator) {
    let s = "";
    array.map((i) => {
      if (i[field]) {
        if (s.length > 0) s += separator;
        s += i[field];
      }
    });
    return s;
  }

  static append(string, text, separator) {
    if (!text) return string;
    let newStr = string;
    if (text && text.length > 0) {
      if (newStr.length > 0) newStr += separator;
      newStr += text;
    }
    return newStr;
  }

  static toISOString(date, format = "DD/MM/YYYY") {
    if (moment(date, moment.ISO_8601).isValid()) return date;
    else {
      const d = moment(date, format, true);
      return d.toString("YYYY-MM-DDThh:mm:ss");
    }
  }

  static isIos() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  }

  // Detects if device is in standalone mode
  static isInStandaloneMode() {
    return window.matchMedia("(display-mode: standalone)").matches;
    //return ('standalone' in window.navigator) && (window.navigator.standalone);
  }

  static isAndroidStandalone() {
    return window.matchMedia("(display-mode: standalone)").matches;
  }

  static Base64Encode(str) {
    return window.btoa(unescape(encodeURIComponent(str)));
  }

  static Base64Decode(str) {
    return decodeURIComponent(escape(window.atob(str)));
  }

  static getLastElement(str, separator) {
    return str.split(separator).pop();
  }

  static rowClass(n) {
    if (n % 2) return "row-even";
    else return "row-odd";
  }

  static toHHmmss(datetime) {
    // datetime comes like 09:59:58.0000000
    // transform to 09:59:58
    if (datetime) {
      return datetime.substring(0, 8);
    }
    return "";
  }

  static formatSingleDates = (dates) => {
    //dates come as "1-jan / 2-jan"
    //in cases where the second is the same as the first one, like in "1-jan / 1-jan", we should return just "1-jan"
    var dateArray = dates.split(" / ");
    if (dateArray[0] === dateArray[1]) return dateArray[0];
    return dates;
  };

  static isDefined(obj) {
    return !this.undefined(obj);
  }

  static isTrue(val) {
    return val === true || val === "true" || val === "True";
  }

  static httpsLink(url) {
    if (!url) return "";
    if (url.startsWith("http://") || url.startsWith("https://")) return url;
    return "https://" + url;
  }

  static sanitizeText(text) {
    return text.replace(/<\/?[^>]+(>|$)/g, "");
  }

  static matchesEmailPattern(email) {
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
  }
}
