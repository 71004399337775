import React, { useState, useEffect, useMemo } from "react";
import MainMenu from "../../../Components/Main-Menu/menu.main";
import MyEventsHeader from "./Components/my.events.header";
import MyEventsList from "./Components/my.events.list";

import { useNavigate } from "react-router-dom";
import ManageEventService from "../../../Services/manage-event-service";
import { toast } from "react-toastify";
import { useUser } from "../../../Components/Common/context";
import MyEventsContentModel from "./Models/MyEventsContentModel.js";

const MyEventsScreen = () => {
  const content = {
    Pending: new MyEventsContentModel("Pending"),
    Submitted: new MyEventsContentModel("Submitted"),
    Approved: new MyEventsContentModel("Approved"),
  };
  const savedActiveTab = localStorage.getItem("myEventsActiveTab");
  const navigate = useNavigate();
  const { user } = useUser();
  //const [events, setEvents] = useState(null);
  const [contentStatus, setContentStatus] = useState({
    view: savedActiveTab || "Pending",
    content: content,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!user || !user.UserID) return;
    //if (!contentStatus.content[contentStatus.view].list) getEvents();
    getEvents();
    //console.log(user);
  }, [user, contentStatus.view]);

  const getEvents = () => {
    setIsLoading(true);
    var status = contentStatus.view;
    var page = contentStatus.content[status]?.page;
    ManageEventService.listEvents({
      Status: status,
      Page: page,
      PageSize: 100,
      DNNUserId: user.UserID,
      // send each user.OrganisationList.Id in the query like userOrganisationds=1&userOrganisationds=2
      OrganisationIds: user.OrganisationList?.map((org) => org.Id) || [0],
    })
      .then((response) => {
        let _content = { ...contentStatus.content };
        let _list = [...(_content[status].list || []), ...response.data.events];
        let _totalPending = response.data.totalPendingRecords || 0;
        let _totalSubmitted = response.data.totalSubmittedRecords || 0;
        let _totalApproved = response.data.totalApprovedRecords || 0;

        let _page = _content[status].page + 1;

        // _list = _list.filter(
        //   (v, i, a) => a.findIndex((t) => t.id === v.id) === i
        // );

        let model = new MyEventsContentModel(
          status,
          _page,
          response.data.totalPages,
          response.data.totalRecords,
          _list
        );
        _content[status] = model;
        setContentStatus({
          view: status,
          content: _content,
          totalPendingRecords: _totalPending,
          totalSubmittedRecords: _totalSubmitted,
          totalApprovedRecords: _totalApproved,
        });

        setIsLoading(false);
      })
      .catch((error) => {
        toast.error("Error getting events");
        setIsLoading(false);
      });
  };

  const goCreateNewEvent = () => {
    navigate("/manage/my-events/new");
  };

  const memoizedList = useMemo(() => {
    if (!contentStatus?.content[contentStatus?.view]?.list) return <></>;
    return (
      <MyEventsList
        content={contentStatus}
        totalPendingRecords={contentStatus.totalPendingRecords}
        totalSubmittedRecords={contentStatus.totalSubmittedRecords}
        totalApprovedRecords={contentStatus.totalApprovedRecords}
        isLoading={isLoading}
        loadMore={getEvents}
        viewChange={(view) => {
          setContentStatus({
            view: view,
            content: contentStatus.content,
          });
        }}
      />
    );
  }, [JSON.stringify(contentStatus), isLoading]);

  return (
    <>
      <MainMenu />
      <div className="main-panel">
        <MyEventsHeader />
        <div className="myEventsWrapper">
          <div className="col-12">
            <div
              role="button"
              className="btn btn-sm btn-dark m-3 mb-2"
              onClick={goCreateNewEvent}
            >
              Add New Event
            </div>
            {memoizedList}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyEventsScreen;
