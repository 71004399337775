import React, { useState, useEffect } from "react";
import MainMenu from "../../Components/Main-Menu/menu.main";
import { toast } from "react-toastify";
import NewsService from "../../Services/news-service";
import { useParams } from "react-router-dom";
import moment from "moment";
const NewsView = ({ ...props }) => {
  const [article, setArticle] = useState(null);
  const { contentId } = useParams();

  useEffect(() => {
    if (contentId) {
      handleGetNews();
    }
  }, []);

  const handleGetNews = () => {
    NewsService.getById(contentId)
      .then((r) => {
        setArticle(r.data.data);
      })
      .catch((e) => {
        console.error(e);
        toast.error("Error getting news");
      });
  };

  const ArticleImage = () => {
    if (!article || !article.Image) return null;
    var url = ``;
    if (article.Image.startsWith("/Portals")) {
      url = `${process.env.REACT_APP_NEWS_BASEURL}${article.Image}`;
    } else {
      url = `${newsItem.Image}`;
    }
    return <img src={`${url}`} className="news-item-full-image" />;
  };

  return (
    <>
      <MainMenu />
      <div className="main-panel">
        <div className="container">
          <div className="news-wrapper col-12">
            <ArticleImage />
            <div className="news-article-header">
              <div className="news-article-category">
                {article?.Category?.toUpperCase()}
              </div>
              <div className="news-article-date">
                {moment(article?.Date).format("D MMM YYYY")}
              </div>
              <h4>{article?.Title?.toUpperCase()}</h4>
            </div>
            <div
              className="news-article"
              dangerouslySetInnerHTML={{
                __html: article?.Description,
              }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsView;
