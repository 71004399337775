import React, { useEffect, useLayoutEffect } from "react";
import "../../../Assets/css/bootstrap/js/bootstrap.bundle.min.js";
import SeriesResultsListExpandedInfo from "./event.details.series.list.item.seriesresults.list.expandedInfo.jsx";

const EventDetailsSeriesListItemSeriesResultsList = ({ series, index }) => {
  const [rankedSeries, setRankedSeries] = React.useState();

  useLayoutEffect(() => {
    const sortedSeriesResults = series?.SeriesResults?.sort((a, b) => {
      return a.Rank - b.Rank;
    });
    setRankedSeries(sortedSeriesResults);
  }, [JSON.stringify(series)]);

  const getDisplayName = (sr) => {
    // return sr.boatName + sailNumberFull or sailNumber (whatever is available)

    var name = sr.boatName || "";
    if (sr.sailNumberFull) {
      return (name += `${name && ` - `}${sr.sailNumberFull}`);
    }
    if (sr.sailNumber) {
      return (name += `${name && ` - `}${sr.sailNumber}`);
    }
    return name;
  };

  const getStats = (sr) => {
    //console.log(series?.RaceType);
    if (series?.RaceType == "Handicap") {
      return (
        <>
          <div className="col-11 mt-2 raceStats">
            <div className="col-12 d-flex">
              <div className="col-6">
                <strong>Total</strong>:{` ${sr?.Total}`}
              </div>
              <div className="col-6">
                <strong>Start HC</strong>:{` ${sr?.InitialRating}`}
              </div>
            </div>
            <div className="col-12 d-flex">
              <div className="col-6">
                <strong>Net</strong>:{` ${sr?.Nett}`}
              </div>
              <div className="col-6">
                <strong>Final HC</strong>:
                {` ${sr?.FinalRating ? sr.FinalRating : ""}`}
              </div>
            </div>
          </div>
        </>
      );
    }
    if (series?.RaceType == "OneDesign") {
      return (
        <>
          <div className="col-11 mt-2 raceStats">
            <div className="col-12 d-flex">
              <div className="col-6">
                <strong>Total</strong>:{` ${sr?.Total}`}
              </div>
            </div>
            <div className="col-12 d-flex">
              <div className="col-6">
                <strong>Net</strong>:{` ${sr?.Nett}`}
              </div>
            </div>
          </div>
        </>
      );
    }
    return <></>;
  };

  return (
    <>
      <h4 className="px-2 fw-bolder">Overall results</h4>
      <div className="accordion accordion-flush " id={`series${series?.Id}`}>
        {rankedSeries?.map((sr, i) => {
          return (
            <div className="accordion-item" key={i}>
              <h2 className="accordion-header" id={`heading${sr.id}`}>
                <button
                  className={`px-2 accordion-button collapsed ${
                    i % 2 === 0 ? "even" : "odd"
                  } align-items-start`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse${sr.id}`}
                  aria-expanded="false"
                  aria-controls="collapseOne"
                >
                  <div className="row w-100">
                    <div className="col-1">
                      <span className="fw-bolder">{`${sr.Rank}`}</span>
                    </div>
                    <div className="col-10">
                      <strong>{`${getDisplayName(sr)}`}</strong>
                      <br />
                      <strong>Helm</strong>:{` ${sr?.helmName}`}
                      {sr?.crew && (
                        <div>
                          <strong>Crew</strong>:{` ${sr?.crew}`}
                        </div>
                      )}
                      {sr?.clubName && (
                        <div>
                          <strong>Club</strong>:{` ${sr?.clubName}`}
                        </div>
                      )}
                      {getStats(sr)}
                    </div>
                  </div>
                </button>
              </h2>
              <div
                id={`collapse${sr.id}`}
                className="accordion-collapse collapse"
                aria-labelledby={`heading${sr.id}`}
                data-bs-parent={`#series${series.Id}`}
              >
                <div className="accordion-body">
                  <SeriesResultsListExpandedInfo
                    series={series}
                    seriesId={series?.Id}
                    seriesResult={sr}
                    raceType={series?.RaceType}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default EventDetailsSeriesListItemSeriesResultsList;
