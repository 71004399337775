import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
const TrainingListItem = ({ course, ...props }) => {
  const navigate = useNavigate();

  const navigateToCourse = (courseDateId) => {
    navigate(`/training/${courseDateId}`);
  };
  return (
    <>
      <div
        className={`training-schedule-item ps-3 pe-1 py-2`}
        role="button"
        onClick={() => navigateToCourse(course.CourseDateId)}
      >
        <div className="training-schedule-item-date">
          <div className="col-3 col-md-1">
            <i className="fas fa-calendar-alt me-2" />
            {course.date}
          </div>
          <div className="col-9 col-md-11 ps-2 pe-2 d-flex flex-column">
            <div className="training-schedule-item-name">
              {course.CourseName}
            </div>
            <div className="training-schedule-item-county">
              {course?.County}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrainingListItem;
