import moment from "moment";
import React, { useState, useEffect, useMemo, Fragment } from "react";
import TrainingListItem from "./training.list.item";

const TrainingCourseList = ({ courses, ...props }) => {
  const list = courses;
  const [yearMonthList, setYearMonthList] = useState(null);

  useEffect(() => {
    // group list by StartDate month and year, ex: January 2021, February 2021, etc.
    if (list) {
      const grouped = list.reduce((r, a) => {
        r[moment(a.CourseStartDate).format("MMMM YYYY")] = [
          ...(r[moment(a.CourseStartDate).format("MMMM YYYY")] || []),
          a,
        ];
        return r;
      }, {});

      //then, for each month, group by day, ex: 1-jan, 2-jan, 3-jan, etc.
      const groupedByDay = Object.keys(grouped).map((key) => {
        const groupedByDay = grouped[key].reduce((r, a) => {
          r[moment(a.CourseStartDate).format("D-MMM").toLowerCase()] = [
            ...(r[moment(a.CourseStartDate).format("D-MMM").toLowerCase()] ||
              []),
            a,
          ];
          return r;
        }, {});
        return {
          yearMonth: key,
          days: Object.keys(groupedByDay).map((key) => {
            return {
              day: key,
              courses: groupedByDay[key],
            };
          }),
        };
      });

      setYearMonthList(groupedByDay);
    }
  }, [list]);

  return (
    <>
      <div className="training-schedule-wrapper">
        {yearMonthList?.map((item, k) => {
          return (
            <Fragment key={k}>
              <div className="training-schedule-yearMonth px-3 py-2">
                {item.yearMonth}
              </div>
              {item.days?.map((day, j) => {
                let isLastDay = j === item.days.length - 1;
                return (
                  <Fragment key={j}>
                    {day.courses?.map((course, i) => {
                      course.date = day.day;
                      var isLastCourse = i === day.courses.length - 1;
                      return (
                        <Fragment key={i}>
                          {/* <CalendarScheduleItem key={i} event={event} /> */}
                          <TrainingListItem course={course} />
                          <hr hidden={isLastDay && isLastCourse} />
                        </Fragment>
                      );
                    })}
                  </Fragment>
                );
              })}
            </Fragment>
          );
        })}
      </div>
    </>
  );
};

export default TrainingCourseList;
