import React, {
  useState,
  useEffect,
  Fragment,
  useMemo,
  useRef,
  useLayoutEffect,
} from "react";
import CalendarScheduleItem from "./calendar.schedule.item";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroller";
import { first } from "rxjs";

const CalendarScheduleWrapper = ({
  events,
  selectedFilter,
  onLoadMore,
  loading,
  ...props
}) => {
  const [originalList, setOriginalList] = useState(null); // [events.events
  const [list, setList] = useState(null);
  const [yearMonthList, setYearMonthList] = useState(null);
  const [isLoading, setIsLoading] = useState(loading);
  const firstLoad = useRef(true);

  useLayoutEffect(() => {
    if (events?.events.length > 0) {
      //setOriginalList((list || []).concat(events.events));
      setList(makeData((list || []).concat(events.events)));
    }
  }, [events]);

  useLayoutEffect(() => {
    //setOriginalList([]);
    setList([]);
  }, [selectedFilter]);

  useEffect(() => {
    if (firstLoad.current) return;
    setIsLoading(loading);
  }, [loading]);

  const makeData = (_list) => {
    setIsLoading(true);
    const grouped = _list.reduce((r, a) => {
      r[a.EventName] = [...(r[a.EventName] || []), a];
      return r;
    }, {});
    Object.keys(grouped).forEach((key) => {
      if (grouped[key].length < 2) {
        delete grouped[key];
      }
    });
    Object.keys(grouped).forEach((key) => {
      grouped[key].sort((a, b) => {
        return new Date(a.CalendarDate) - new Date(b.CalendarDate);
      });
    });
    Object.keys(grouped).forEach((key) => {
      var minDate = moment(grouped[key][0].CalendarDate);
      var maxDate = moment(grouped[key][grouped[key].length - 1].CalendarDate);
      var difference = maxDate.diff(minDate, "days");
      if (difference > 7) {
        delete grouped[key];
      } else {
        grouped[key][0].Filtered = true;
        grouped[key].splice(1);
      }
    });
    Object.keys(grouped).forEach((key) => {
      _list = _list.filter((item) => {
        return item.Id !== grouped[key][0].Id;
      });
    });
    Object.keys(grouped).forEach((key) => {
      _list.push(grouped[key][0]);
    });
    _list.sort((a, b) => {
      var aDate = new Date(a.CalendarDate);
      var bDate = new Date(b.CalendarDate);
      return aDate - bDate;
    });

    if (selectedFilter === "past") {
      _list.reverse();
    }
    //distinct
    _list = _list.filter((v, i, a) => a.findIndex((t) => t.Id === v.Id) === i);
    setIsLoading(loading);
    return _list;
  };

  useEffect(() => {
    // group list by StartDate month and year, ex: January 2021, February 2021, etc.
    if (list && events?.events?.length > 0) {
      const grouped = list.reduce((r, a) => {
        r[moment(a.CalendarDate).format("MMMM YYYY")] = [
          ...(r[moment(a.CalendarDate).format("MMMM YYYY")] || []),
          a,
        ];
        return r;
      }, {});

      //then, for each month, group by day, ex: 1-jan, 2-jan, 3-jan, etc.
      const groupedByDay = Object.keys(grouped).map((key) => {
        const groupedByDay = grouped[key].reduce((r, a) => {
          r[moment(a.CalendarDate).format("D-MMM").toLowerCase()] = [
            ...(r[moment(a.CalendarDate).format("D-MMM").toLowerCase()] || []),
            a,
          ];
          return r;
        }, {});
        return {
          yearMonth: key,
          days: Object.keys(groupedByDay).map((key) => {
            return {
              day: key,
              events: groupedByDay[key],
            };
          }),
        };
      });
      setYearMonthList(groupedByDay);
    }
  }, [list]);

  useEffect(() => {
    firstLoad.current = false;
  }, []);

  const handleLoadMore = () => {
    if (!onLoadMore || isLoading) return;
    onLoadMore();
  };

  return (
    <>
      <div className="calendar-schedule-wrapper">
        <InfiniteScroll
          pageStart={1}
          loadMore={() => handleLoadMore()}
          hasMore={
            selectedFilter != "future" && !isLoading && !firstLoad.current
          }
          initialLoad={false}
          loader={
            <div
              className="fw-bold col-12 d-flex justify-content-center p-2 align-items-center"
              key={0}
            >
              <i className="fa fa-spinner fa-spin me-1" aria-hidden="true"></i>
              Loading more events...
            </div>
          }
          threshold={60}
        >
          {yearMonthList?.map((item, k) => {
            return (
              <Fragment key={k}>
                <div className="calendar-schedule-yearMonth px-3 py-2">
                  {item.yearMonth}
                </div>
                {item.days?.map((day, j) => {
                  let isLast = j === item.days.length - 1;
                  return (
                    <Fragment key={j}>
                      {day.events?.map((event, i) => {
                        event.date = day.day;
                        var isLastComp = i === day.events?.length - 1;
                        return (
                          <Fragment key={event.Id}>
                            <CalendarScheduleItem key={i} event={event} />
                            <hr hidden={isLast && isLastComp} />
                          </Fragment>
                        );
                      })}
                    </Fragment>
                  );
                })}
              </Fragment>
            );
          }) || <></>}
        </InfiniteScroll>
      </div>
    </>
  );
};

export default CalendarScheduleWrapper;
