import React, { useState, useEffect, useLayoutEffect } from "react";
import EventService from "../../../Services/event-service";

const WideViewTableRow = ({ series, result, type }) => {
  const [results, setResults] = useState();
  useLayoutEffect(() => {
    if (!result) return;
    var results = [...series?.Races]?.reduce((results, race) => {
      const competitorResult = race?.raceResults?.find(
        (res) => res.CompetitorId === result.CompetitorId
      );
      if (competitorResult) {
        results.push(competitorResult);
      }
      return results;
    }, []);
    setResults(results);
  }, [JSON.stringify(result)]);

  const getCompetitorResults = (seriesResult) => {
    return;
    EventService.getCompetitorResults(seriesResult.CompetitorId, series.Id)
      .then((data) => {
        setResults(data.results);
      })
      .catch((error) => {
        console.error(error);
        // Handle any errors here
      });
  };
  const getRaceTypeRowCells = () => {
    if (type == "Handicap") {
      return (
        <>
          <td className="text-center">{result?.InitialRating}</td>
          <td className="text-center">
            {result?.FinalRating ? result.InitalRating : ""}
          </td>
        </>
      );
    }
    return <></>;
  };
  const getDisplayName = (sr) => {
    // return sr.boatName + sailNumberFull or sailNumber (whatever is available)

    var name = sr.boatName || "";
    if (sr.sailNumberFull) {
      return (name += `${name && ` - `}${sr.sailNumberFull}`);
    }
    if (sr.sailNumber) {
      return (name += `${name && ` - `}${sr.sailNumber}`);
    }
    return name;
  };

  const getRaceResultsRowCells = () => {
    return (
      <>
        {series?.Races?.map((r, i) => {
          let raceResult = results?.find((x) => x.RaceId == r.Id);
          return (
            <td key={i} className="text-center">{`${
              raceResult?.PointsFormatted ? raceResult?.PointsFormatted : ""
            }`}</td>
          );
        })}
      </>
    );
  };

  return (
    <>
      <tr>
        <td className="text-center">{result.Rank}</td>
        <td className="text-left">{getDisplayName(result)}</td>
        <td className="text-left" style={{ width: "200px" }}>
          <strong>Helm</strong>:{` ${result?.helmName}`}
          {result?.crew && (
            <div
              style={{
                fontSize: "13px",
              }}
            >
              <strong>Crew</strong>:{` ${result?.crew}`}
            </div>
          )}
          {result?.clubName && (
            <div
              style={{
                fontSize: "13px",
              }}
            >
              <strong>Club</strong>:{` ${result?.clubName}`}
            </div>
          )}
        </td>
        {getRaceTypeRowCells()}
        {getRaceResultsRowCells()}
        <td className="text-center">{result.Total}</td>
        <td className="text-center">{result.Nett}</td>
      </tr>
    </>
  );
};

export default WideViewTableRow;
