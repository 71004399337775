import React, { useState, useEffect, useRef } from "react";
import FormTextInput from "../../../Components/Common/Inputs/text.form.input";
import { login } from "../../../Services/login-instance";
import ContextService from "../../../Services/context-service";
import { toast } from "react-toastify";
import DNNUserModel from "../../../Models/user.model";

const LoginForm = ({ ...props }) => {
  const [username, setUsername] = useState(""); // disgrafic - host
  const [password, setPassword] = useState(""); // zaq12wsx - Iwant2CU_harrY
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const userRef = useRef(null);
  const passwordRef = useRef(null);

  useEffect(() => {
    if (!userRef.current) return;
    userRef.current.focus();
  }, [userRef]);

  const handleLogin = (e) => {
    e.preventDefault();
    login(
      userRef.current.value,
      passwordRef.current.value,
      (r) => {
        ContextService.getUserContext().then((u) => {
          var user = new DNNUserModel(u.data.ctx);
          if (user.IsAdmin || user.IsOrganisation) {
            window.location.href = "/manage/my-events";
          } else {
            window.location.href = "/";
          }
        });
      },
      (e) => {
        toast.error("The username or password provided are not valid");
      }
    );
  };

  useEffect(() => {
    if (isLoggedIn) {
      alert("You are logged in!");
    }
  }, [isLoggedIn]);

  const goHome = () => {
    window.location.href = "/";
  };

  return (
    <>
      <form
        className="loginForm d-flex justify-content-center text-center"
        onSubmit={(e) => handleLogin(e)}
        method="post"
      >
        <div className="col-auto">
          <div className="fw-bold mb-1">Username</div>
          <div>
            <input type="text" name="memberId" ref={userRef} />
          </div>
          <div className="fw-bold mt-2 mb-1">Password</div>
          <div>
            <input type="password" name="memberId" ref={passwordRef} />
          </div>
          <div className="col-12 d-flex flex-column mt-2">
            <button className="btn btn-sm btn-dark mt-2 col-12" type="submit">
              Login
            </button>
            <div
              className="btn btn-sm btn-secondary mt-2 col-12"
              role="button"
              onClick={goHome}
            >
              Cancel
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default LoginForm;
