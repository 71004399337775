import { BehaviorSubject } from "rxjs";

const defaultCacheTimeOut = process.env.REACT_APP_CACHE_TIMEOUT;
const defaultLogStatus = process.env.REACT_APP_LOG_STATUS;

const storageName = "sailingLiveApp-settings";
const _saSettings =
  typeof window !== "undefined"
    ? JSON.parse(localStorage.getItem(storageName))
    : null;
const subAppSettings = new BehaviorSubject(_saSettings);

export const AppSettingService = {
  setData,
  appSettings: subAppSettings.asObservable(),
  get appSettings() {
    return subAppSettings.value;
  },
  getCacheTimeOut,
  isLogEnabled,
};

function getCacheTimeOut() {
  //CommonService.addLog('getCacheTimeOut',`AppSettingService:Get Cache TimeOut starts.`);
  if (AppSettingService.appSettings) {
    let res = AppSettingService.appSettings.CacheTimeOut;
    //CommonService.addLog('getCacheTimeOut',`AppSettingService:Get Cache TimeOut.${res}`);
    if (res) {
      return parseInt(res);
    } else {
      return defaultCacheTimeOut;
    }
  } else {
    return defaultCacheTimeOut;
  }
}

function isLogEnabled() {
  //CommonService.addLog('isLogEnabled',`AppSettingService:Is Log Enabled status starts.`);
  if (AppSettingService.appSettings) {
    let res = AppSettingService.appSettings.Logging;
    // CommonService.addLog('isLogEnabled',`AppSettingService:Is Log Enabled status starts.${res}`);
    return res === "1";
  } else {
    return defaultLogStatus == 1;
  }
}
function setData(data) {
  if (data) {
    let obj = {};
    data.forEach((e) => {
      obj[e.Name] = e.Value;
    });
    if (typeof window !== "undefined") {
      localStorage.setItem(storageName, JSON.stringify(obj));
    }
  }
}
