import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import MainMenu from "../../Components/Main-Menu/menu.main";
import TrainingCourseHeader from "./Components/training.course.header";
import TrainingService from "../../Services/training-service";
import TrainingFilter from "./Components/training.list.filter";

const TrainingView = ({ ...props }) => {
  const [course, setCourse] = useState(null);
  const { courseDateId } = useParams();

  useEffect(() => {
    if (!courseDateId) return;
    getCourse();
  }, [courseDateId]);

  const getCourse = () => {
    TrainingService.getCourse(courseDateId)
      .then((r) => {
        setCourse(r.data.data);
      })
      .catch((e) => {
        console.error(e);
        // Handle any errors here
      });
  };

  const Instructors = () => {
    return (
      <>
        <div className="fw-bold">Instructors</div>
        <div className="col-12" hidden={!course.LeadInstructor}>
          Lead Instructor: {course.LeadInstructor}
        </div>
        <div className="col-12" hidden={!course.SecondInstructor}>
          Second Instructor: {course.SecondInstructor}
        </div>
        <div className="col-12" hidden={!course.ModeratingInstructor}>
          Moderating Instructor: {course.ModeratingInstructor}
        </div>
      </>
    );
  };

  const Notes = () => {
    if (!course || !course.CourseNotes) return <></>;

    //http / https url regex to detect any url
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    const urlify = (text) => {
      return text.replace(urlRegex, function (url) {
        return '<a href="' + url + '" target="_blank">' + url + "</a>";
      });
    };

    return (
      <>
        <div className="fw-bold mt-3">Notes</div>
        <div
          className="courseNotes"
          dangerouslySetInnerHTML={{ __html: urlify(course.CourseNotes) }}
        ></div>
      </>
    );
  };

  if (!course)
    return (
      <>
        <MainMenu />
      </>
    );
  return (
    <>
      <MainMenu />
      <div className="main-panel">
        <TrainingFilter text="Course Details" />
        <TrainingCourseHeader course={course} />
        <div className="course-body col-12">
          <Instructors />
          <Notes />
        </div>
      </div>
    </>
  );
};

export default TrainingView;
