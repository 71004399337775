export default class MyEventsContentModel {
  constructor(
    status,
    page,
    totalPages,
    totalRecords,
    list,
    totalPending,
    totalSubmitted,
    totalApproved
  ) {
    this.status = status;
    this.page = page || 1;
    this.totalPages = totalPages || 0;
    this.totalRecords = totalRecords || 0;
    this.list = list;
    this.hasMore = (this.list?.length || 0) < this.totalRecords;
  }
}
