import moment from "moment";
import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroller";
import { useNavigate } from "react-router-dom";

const MyEventsList = ({
  content,
  loadMore,
  viewChange,
  isLoading,
  totalPendingRecords,
  totalSubmittedRecords,
  totalApprovedRecords,
}) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(content?.view || "Pending");
  const [viewEvents, setViewEvents] = useState(null);

  useEffect(() => {
    if (!content) return;
    setViewEvents(content.content[activeTab].list);
  }, [JSON.stringify(content), activeTab]);

  const EventItem = ({ event }) => {
    const status = (event) => {
      if (event.approvedDate != null)
        return <span className="text-success">Approved</span>;

      if (event.submittedDate != null && event.approvedDate == null)
        return <span className="text-warning">Submitted</span>;

      if (event.submittedDate == null && event.approvedDate == null)
        return <span className="text-dark">Pending</span>;

      return <span className="text-danger">Unknown</span>;
    };

    const dates = (event) => {
      //return event.dates as D-MMM separated by commas
      return event.dates
        ?.map((date) => moment(date).format("D-MMM"))
        .join(", ");
    };

    return (
      <>
        <div className="col-12" onClick={() => navigate(`edit/${event.id}`)}>
          <div className="col-12 d-flex px-3">
            <div className="col-10 col-md-11 fw-bold">{event.eventName}</div>
            <div className="col-2 col-md-1 fw-bold">{status(event)}</div>
          </div>
          <div className="col-12 px-3">{dates(event)}</div>
        </div>
        <hr />
      </>
    );
  };

  const handleTabChange = (tab) => {
    if (activeTab === tab) return;
    setActiveTab(tab);
    localStorage.setItem("myEventsActiveTab", tab);
    viewChange(tab);
  };

  const EventStatusTabs = () => {
    return (
      <div className="col-12 bg-dark d-flex eventStatusTabs mt-2 mb-3">
        <div
          className={`col-4 py-2 text-center tab ${
            activeTab === "Pending" ? "active" : ""
          }`}
          onClick={() => handleTabChange("Pending")}
        >
          Pending
          {totalPendingRecords > 0 && (
            <span className="badge bg-white text-dark ms-1">
              {totalPendingRecords}
            </span>
          )}
        </div>
        <div
          className={`col-4 py-2 text-center tab ${
            activeTab === "Submitted" ? "active" : ""
          }`}
          onClick={() => handleTabChange("Submitted")}
        >
          Submitted
          {totalSubmittedRecords > 0 && (
            <span className="badge bg-white text-dark ms-1">
              {totalSubmittedRecords}
            </span>
          )}
        </div>
        <div
          className={`col-4 py-2 text-center tab ${
            activeTab === "Approved" ? "active" : ""
          }`}
          onClick={() => handleTabChange("Approved")}
        >
          Approved
          {totalApprovedRecords > 0 && (
            <span className="badge bg-white text-dark ms-1">
              {totalApprovedRecords}
            </span>
          )}
        </div>
      </div>
    );
  };
  return (
    <>
      <EventStatusTabs />
      {isLoading && (
        <div className="fw-bold d-flex flex-column justify-content-center align-items-center">
          <Spinner animation="border" className="mb-2" />
          Loading...
        </div>
      )}
      {viewEvents && viewEvents?.length !== 0 && (
        <InfiniteScroll
          loadMore={loadMore}
          hasMore={!isLoading && content.content[activeTab].hasMore}
          threshold={150}
          initialLoad={false}
        >
          {viewEvents.map((ev) => {
            return <EventItem key={ev.id} event={ev} />;
          })}
        </InfiniteScroll>
      )}
    </>
  );
};

export default MyEventsList;
