import moment from "moment";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Util from "../../../Components/Common/util";

const EventDetailsHeader = ({ event, ...props }) => {
  const { fleetId } = useParams();
  const navigate = useNavigate();

  const navigateToSeries = (serieId) => {
    navigate(`/event/${fleetId}/${serieId}`);
  };
  const encodeClubVenue = (clubVenue) => {
    //return return encodeURIComponent(clubVenue) if any of address, town, county, or eircode is null
    if (
      event?.Address === null ||
      event?.Town === null ||
      event?.County === null ||
      event?.Eircode === null
    ) {
      return encodeURIComponent(clubVenue);
    }
    //make encoded uri component for google maps using address, town, county, and eircode
    let string = `${event?.Address}, ${event?.Town}, ${event?.County}, ${event?.Eircode}`;
    return encodeURIComponent(string);
  };
  const AddressData = () => {
    var address = event?.Address;
    var town = event?.Town;
    var county = event?.County;
    var eircode = event?.Eircode;

    //address, town, county, and eircode can be null.
    //show the data as Address, Town, County, Eircode
    var addressData = "";
    if (address) {
      addressData += `${address}, `;
    }
    if (town) {
      addressData += `${town}, `;
    }
    if (county) {
      addressData += `${county}, `;
    }
    if (eircode) {
      addressData += `${eircode}`;
    }

    if (addressData === "") {
      return null;
    }

    // get a encoded google maps query
    var encodedAddress = encodeClubVenue(addressData);
    return (
      <>
        {addressData}
        <a
          href={`https://www.google.com/maps/search/?api=1&query=c${encodedAddress}`}
          target="_blank"
          className="ms-2"
        >
          <i className="fas fa-map-marker-alt fa-2x" />
        </a>
      </>
    );
  };
  const ShowLogo = () => {
    if (event.EventLogoURL) {
      return <img src={event.EventLogoURL} className="EventLogo" />;
    }
    if (event.SponsorLogoURL) {
      return (
        <div className="m-2 d-flex justify-content-center">
          <img src={event.SponsorLogoURL} className="EventLogo" />
        </div>
      );
    }
    if (event.OrganisationLogo) {
      return (
        <img
          src={`
  data:image/png;base64,${event.OrganisationLogo}
  `}
          className="EventLogo"
        />
      );
    }
    return <></>;
  };

  if (!event) return <></>;
  return (
    <>
      <div className="EventDetailsHeader">
        <div className="col-12">
          <div className="EventDetailsHeaderEventName">{event.EventName}</div>
          <div className="d-flex align-items-center justify-content-start">
            <div className="col-9 col-md-11">
              <div className="EventDetailsHeaderEventDates mt-1">
                <i className="fas fa-calendar ms-1 me-2" />
                {Util.formatSingleDates(event.Dates)}
              </div>
              {event.Name && (
                <div className="EventDetailsHeaderEventClub mt-1 ps-4 fw-bold">
                  {event.EventName}
                </div>
              )}
              <div className="EventDetailsHeaderEventClub mt-1 ms-4 fw-bold">
                {event.ClubVenue}
              </div>
              <div
                className="EventDetailsHeaderEventClub mt-1 ms-4 fw-bold"
                hidden={!AddressData()}
              >
                {AddressData()}
              </div>
              <div
                className="EventDetailsHeaderEventClub mt-3 fw-bold"
                hidden={!event.EventType}
              >
                <i className="fas fa-fw ms-1 me-2" />
                Type: {event.EventType}
              </div>
              <div
                className="EventDetailsHeaderEventClub mt-1 fw-bold"
                hidden={!event.OpenTo}
              >
                <i className="fas fa-fw ms-1 me-2" />
                Open to {event.OpenTo == "All" ? "all" : "Club members only"}
              </div>
              <div
                className="EventDetailsHeaderEventClub mt-1 fw-bold"
                hidden={!event.PrincipalRaceOfficer}
              >
                <i className="fas fa-fw ms-1 me-2" />
                Race Officer: {event.PrincipalRaceOfficer}
              </div>
              <div
                className="calendar-list-item-organiser-email mt-3 fw-bold"
                hidden={!event.OrganiserEmail}
              >
                <i className="fas fa-at ms-1 me-2" />
                <a href={`mailto:${event.OrganiserEmail}`}>
                  {event.OrganiserEmail}
                </a>
              </div>
              <div
                className="calendar-list-item-event-website"
                hidden={!event.EventWebsite}
              >
                <i className="fas fa-link ms-1 me-2" />
                <a
                  href={Util.httpsLink(event.EventWebsite)}
                  target="_blank"
                  className="col-12 d-flex"
                >
                  Organiser website
                </a>
              </div>
            </div>

            <div className="col-3 col-md-1 ps-0">
              <ShowLogo />
            </div>
          </div>
        </div>
        {/* <div className="fw-bold fst-italic mt-2 me-1 d-flex justify-content-end">
          Results by {event.ResultsFrom} - Last updated{" "}
          {moment(event.LastUpdatedDate).format("D-MMM-YYYY h:mm")}
        </div> */}
      </div>
    </>
  );
};

export default EventDetailsHeader;
