import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import Util from "../../../Components/Common/util";

const EventListItem = ({ event, index, isSearch }) => {
  const style = index % 2 === 0 ? "even" : "odd";

  const formatSingleDates = (dates) => {
    //dates come as "01/Jan - 02/Jan"
    //in cases where the second is the same as the first one, like in "01/Jan - 01/Jan", we should return just "01/Jan"
    var dateArray = dates.split(" - ");
    if (dateArray[0] === dateArray[1]) return dateArray[0];
    return dates;
  };
  return (
    <div className={style}>
      <Link
        className={`EventListItemLink`}
        to={`/event-details/${event.Id}/${moment(event.CalendarDate).format(
          "YYYY-MM-DD"
        )}`}
        state={{ isSearch: isSearch }}
      >
        <div id={event.Id} className="col-12 d-flex mx-0 EventListItem">
          <div className="col-3 col-md-1 d-flex justify-content-center">
            {event?.EventLogoURL && (
              <img src={event.EventLogoURL} className="EventLogo" />
            )}
          </div>
          <div className="col-9 col-md-11">
            <div className="EventListItemEventDates d-flex">
              <div className="col-12 d-flex justify-content-start">
                <i className="fas fa-calendar me-1" />
                {Util.formatSingleDates(event.Dates)}
              </div>
            </div>
            <div className="EventListItemEventName mb-0">{event.EventName}</div>
            {event.Name && (
              <div className="EventListItemEventClub d-flex">
                <div className="col-12 d-flex justify-content-start fw-bold">
                  {event.Name}
                </div>
              </div>
            )}
            <div className="EventListItemEventClub d-flex">
              <div className="col-12 d-flex justify-content-start">
                {event.ClubVenue}
              </div>
            </div>
            <div className="EventListItemEventFleet d-none">
              <div className="col-1 d-flex justify-content-center align-items-center">
                <i className="fas fa-flag ms-2 me-1" />
              </div>
              <div className="col-11 d-flex justify-content-start">
                {event.Fleet}
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default EventListItem;
