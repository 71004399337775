import React, { useState, useEffect, useMemo } from "react";
import MainMenu from "../../Components/Main-Menu/menu.main";
import LoginForm from "./Components/login.form";
import { animationFrameScheduler } from "rxjs";
import LoginMenu from "../../Components/Main-Menu/menu.login";
import PasswordRecoveryInfo from "./Components/password.recovery.info";

const LoginScreen = ({ ...props }) => {
  return (
    <>
      <LoginMenu />
      <div className="container-sm loginContainer">
        <LoginForm />
        <PasswordRecoveryInfo />
      </div>
    </>
  );
};

export default LoginScreen;
