import React from "react";

const NewVersionBanner = () => {
  return (
    <div
      id="newVersion"
      style={{
        display: "none",
        position: "fixed",
        bottom: "0",
        width: "100%",
        padding: "1em 1em",
        backgroundColor: "#e9971d",
        color: "white",
        borderTop: "0.25rem solid black",
      }}
    >
      <div className="d-flex">
        <i
          className="fas fa-spinner fa-spin m-1"
          style={{ width: "1em", height: "1em" }}
        />
        <div className="ml-1 ">
          New version detected.
          <br />
          Please wait while app is reloading...
          <br />
          If the app does not reload,{" "}
          <a href="/" style={{ color: "white" }}>
            click here
          </a>
        </div>
      </div>
    </div>
  );
};

export default NewVersionBanner;
