import React, { useEffect, useState } from "react";
import FormTextInput from "../../../Components/Common/Inputs/text.form.input.jsx";
import { useForm } from "react-hook-form";
import CompetitorsFiltersModel from "../Models/competitors.filters.model";
const CompetitorsSearchFilters = ({
  activeFilter,
  onUpdateFilter,
  onSubmitFilter,
}) => {
  const [filter, setFilter] = useState(new CompetitorsFiltersModel());
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (!activeFilter) return;
    setValue("BoatName", activeFilter.BoatName);
    setValue("HelmName", activeFilter.HelmName);
    setFilter(activeFilter);
  }, [JSON.stringify(activeFilter)]);

  const onSubmit = (data) => {
    let searchData = {
      tab: "Competitors",
      filter: data,
    };
    onSubmitFilter(searchData);
  };
  const updateParent = (data) => {
    onUpdateFilter(data);
  };

  const handleChange = (name, value) => {
    let newData = { ...filter, [name]: value };
    setFilter(newData);
    updateParent(newData);
  };
  const disableSubmit = () => {
    if (filter.BoatName || filter.HelmName || filter.CrewName) return false;

    return true;
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormTextInput
          name="BoatName"
          label="Boat Name"
          type="text"
          register={register}
          registerOptions={{
            value: filter.BoatName,
            onChange: (e) => handleChange("BoatName", e.target.value),
          }}
          errors={errors}
          groupClassName="form-control text-start"
          labelClasses={"fw-bold"}
          inline
        />
        <FormTextInput
          name="HelmName"
          label="Helm Name"
          type="text"
          register={register}
          registerOptions={{
            value: filter.HelmName,
            onChange: (e) => handleChange("HelmName", e.target.value),
          }}
          errors={errors}
          groupClassName="form-control text-start"
          labelClasses={"fw-bold"}
          inline
        />
        <FormTextInput
          name="CrewName"
          label="Crew Name"
          type="text"
          register={register}
          registerOptions={{
            value: filter.CrewName,
            onChange: (e) => handleChange("CrewName", e.target.value),
          }}
          errors={errors}
          groupClassName="form-control text-start"
          labelClasses={"fw-bold"}
          inline
        />
        <div className="col-12 d-flex my-4">
          <div className="col-4 ms-1 me-2"></div>
          <button
            type="submit"
            className="btn btn-dark searchBtn"
            disabled={disableSubmit()}
          >
            Search Competitors
          </button>
        </div>
      </form>
    </>
  );
};
export default CompetitorsSearchFilters;
