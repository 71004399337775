import { CacheManager } from "./Services/cache-service.jsx";
import { createBrowserHistory } from "history";
import NotificationService from "./Services/notification-service.jsx";
import { PrecacheController } from "workbox-precaching";
import { registerRoute } from "workbox-routing";
import { NetworkOnly } from "workbox-strategies";
const PRECACHE = "sailinglive-precache-v1";
const RUNTIME = "sailinglive-runtime";

const PRECACHE_URLS = [
  "/manifest.json",
  "/favicon.ico",
  "/Assets/fonts/open-sans.css",
  "/Assets/css/bootstrap.min.css",
  "/Assets/fontawesome/fontawesome.min.css",
  "/Assets/fontawesome/solid.min.css",
  "/Assets/webfonts/fa-solid-900.eot",
  "/Assets/webfonts/fa-solid-900.svg",
  "/Assets/webfonts/fa-solid-900.ttf",
  "/Assets/webfonts/fa-solid-900.woff",
  "/Assets/webfonts/fa-solid-900.woff2",
  "/sailing_96x96.png",
  "/sailing_144x144.png",
  "/sailing_192x192.png",
  "/sailing_512x512.png",
  "/Sailing_LIVE_Logo121x113.png",
  "/Sailing_LIVE_Logo121x113TEST.png",
];
const manifest = self.__WB_MANIFEST;
const precacheController = new PrecacheController();
precacheController.addToCacheList(PRECACHE_URLS);
let timeout = 5;
var dataCacheName = "sailingLive-cache";
let cacheName = "sailingLive";
const isLocalhost = Boolean(
  self.location.hostname === "localhost" ||
    self.location.hostname === "[::1]" ||
    self.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);
registerRoute(({ url }) => url.pathname.includes("main."), new NetworkOnly());

self.addEventListener("install", (event) => {
  console.log("installing service worker");
  event.waitUntil(
    caches
      .open(PRECACHE)
      .then((cache) => cache.addAll(PRECACHE_URLS))
      .then(self.skipWaiting())
  );
});

self.addEventListener("activate", (event) => {
  event.waitUntil(clearCache());
});

self.addEventListener("fetch", (event) => {
  // exclude main js and css chunks (main.[hash].js, main.[hash].css)
  if (event.request.url.includes("main.")) {
    console.log("skipping main chunk");
    return false;
  }

  const cacheKey = precacheController.getCacheKeyForURL(event.request.url);
  event.respondWith(
    caches.match(cacheKey).then((cachedResponse) => {
      if (cachedResponse) {
        //console.log("found in cache:", cachedResponse);
        return cachedResponse;
      }
      return fetch(event.request);
    })
  );
});

self.addEventListener(
  "visibilitychange",
  function () {
    if (!document.hidden) {
      refreshCache();
      let inactiveSince = localStorage.getItem("SAILINGLIVE_inactiveSince");
      if (inactiveSince) {
        let inactiveTime = new Date().getTime() - inactiveSince;
        if (inactiveTime > 1000 * 60 * 60 * 48) {
          console.log("Inactive for more than 48 hours");
          localStorage.setItem("SAILINGLIVE_calendarSavedFrom", "future");
        }
      }
    } else {
      //save the current time to local storage
      localStorage.setItem("SAILINGLIVE_inactiveSince", new Date().getTime());
    }
  },
  false
);

self.addEventListener("before", (event) => {
  localStorage.setItem("SAILINGLIVE_calendarSavedFrom", "future");
});

// event listener for app closing

export function register(config) {
  const history = createBrowserHistory();
  history.listen(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.getRegistration().then((reg) => {
        if (!reg) {
          return null;
        }
        reg.update();
      });
    }
  });

  if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
    const publicUrl = new URL(process.env.PUBLIC_URL, self.location.href);
    if (publicUrl.origin !== self.location.origin) {
      return;
    }
    self.addEventListener("load", () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      if (isLocalhost) {
        checkValidServiceWorker(swUrl, config);
      } else {
        registerValidSW(swUrl, config);
      }
    });
    self.addEventListener("message", (event) => {
      if (event.data && event.data.type === "SKIP_WAITING") {
        self.skipWaiting();
      }
    });
  }
}

function refreshCache() {
  if (hostReachable()) {
    CacheManager.validateCache("SAILINGLIVE_YEARS").then(function (res) {
      if (res == true) {
        //console.log("Cache is refreshing");
        clearCache();
      }
    });
  }
}

function hostReachable() {
  return fetch("/?rand=" + Math.floor((1 + Math.random()) * 0x10000))
    .then((response) => {
      return (
        (response.status >= 200 && response.status < 300) ||
        response.status === 304
      );
    })
    .catch((error) => {
      return false;
    });
}

function registerValidSW(swUrl, config) {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      const waitingServiceWorker = registration.waiting;
      if (waitingServiceWorker) {
        waitingServiceWorker.addEventListener("statechange", (event) => {
          if (event.target.state === "activated") {
          }
        });
        waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
      }

      registration.onUpdate = () => {
        if (registration.waiting) {
          registration.waiting.postMessage({ type: "SKIP_WAITING" });
        }
      };

      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }

        if (registration && registration.waiting) {
          registration.waiting.postMessage({ type: "SKIP_WAITING" });
        }
        clearAllCache();
        NotificationService.ServiceWorkerEvent.dispatch(true);

        installingWorker.onstatechange = () => {
          if (installingWorker.state === "installed") {
            if (navigator.serviceWorker.controller) {
              // if (config && config.onUpdate) {
              //   var newVersion = document.querySelector("#newVersion");
              //   newVersion.style.display = "block";
              //   console.log("New version available", newVersion.style.display);
              //   config.onUpdate(registration);
              //   setTimeout(() => {
              //     self.location.reload();
              //   }, 2500);
              // } else {
              //   setTimeout(() => {
              //     self.location.reload();
              //   }, 2500);
              // }
              clearAllCache();
            } else {
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch((error) => {
      console.error("Error during service worker registration:", error);
    });
}

function checkValidServiceWorker(swUrl, config) {
  fetch(swUrl)
    .then((response) => {
      const contentType = response.headers.get("content-type");
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf("javascript") === -1)
      ) {
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {});
        });
      } else {
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    });
}

function clearCache() {
  return caches
    .keys()
    .then((cacheNames) => {
      return Promise.all(
        cacheNames.map((cacheName) => {
          if (cacheName.startsWith("sailingLive")) {
            return caches.delete(cacheName);
          }
        })
      );
    })
    .then(() => {
      if (self.clients && self.clients.claim) {
        return self.clients.claim();
      }
    });
}

function addMinutes(date, minutes) {
  var DateObject = new Date(String(date).replace(/\ /g, "T") + "Z"),
    modifiedDate =
      DateObject.getTime() +
      minutes * 60000 +
      new Date(DateObject).getTimezoneOffset() * 60 * 1000;
  return (date = modifiedDate);
}

export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister();
    });
  }
}

function clearAllCache() {
  return;
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    registrations.forEach((registration) => {
      registration.unregister();
    });
  });

  caches
    .keys()
    .then((cacheNames) => {
      cacheNames.forEach((cacheName) => {
        caches.delete(cacheName);
      });
    })
    .then(() => {});
}

async function isCacheExpired() {
  if ("caches" in self) {
    return caches.open(dataCacheName).then((cache) => {
      return cache.match("SAILINGLIVE_YEARS").then((item) => {
        let cacheTime = item.statusText;
        console.log("cacheTime", cacheTime);
        let dtNow = new Date().toLocaleString();
        let cacheExpireTime = addMinutes(cacheTime, timeout);
        let now = addMinutes(dtNow, 1);
        return new Date(now) > new Date(cacheExpireTime);
      });
    });
  }
}
