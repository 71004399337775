import React, { useEffect, useLayoutEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import NewsListItem from "./news.list.item";

const NewsList = ({ data, onLoadMore, isLoading, page, filter, ...props }) => {
  const [news, setNews] = useState();
  const [totalPages, setTotalPages] = useState(1);
  const [totalNews, setTotalNews] = useState(0);
  const [newsToShow, setNewsToShow] = useState(news);

  useLayoutEffect(() => {
    if (!data) return;
    setTotalPages(data.TotalPages);
    setTotalNews(data.TotalRows);

    if (!data.News) return;
    setNews([...(news || []), ...data.News]);
  }, [data]);

  const handleLoadMore = () => {
    if (isLoading || !onLoadMore || news.length >= totalNews) return;
    onLoadMore(page + 1);
  };

  useEffect(() => {
    if (!news) return;
    let filteredNews = news?.filter(
      (n) => filter.Category == "All" || n.Category == filter.Category
    );

    //remove duplicates (by ContentId)
    filteredNews = filteredNews.filter(
      (v, i, a) => a.findIndex((t) => t.ContentId === v.ContentId) === i
    );
    setNewsToShow(filteredNews);
  }, [filter, news]);

  const handleMap = () => {};
  return (
    newsToShow && (
      <InfiniteScroll
        className="news-infinite-scroll-container"
        pageStart={0}
        loadMore={() => handleLoadMore()}
        hasMore={news?.length < totalNews}
        loader={
          <div
            className="fw-bold col-12 d-flex justify-content-center p-2 align-items-center"
            key={0}
          >
            <i className="fa fa-spinner fa-spin me-1" aria-hidden="true"></i>
            Loading more news...
          </div>
        }
        threshold={150}
      >
        {newsToShow?.map((article, i) => (
          <NewsListItem
            id={article.ContentId}
            key={article?.ContentId || i}
            newsItem={article}
          />
        ))}
      </InfiniteScroll>
    )
  );
};

export default NewsList;
