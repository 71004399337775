import React, { useEffect, useState } from "react";

const RaceResultsOneDesignTable = ({ results, ...props }) => {
  const getDisplayName = (sr) => {
    // return sr.boatName + sailNumberFull or sailNumber (whatever is available)

    var name = sr.boatName || "";
    if (sr.sailNumberFull) {
      return (name += `${name && ` - `}${sr.sailNumberFull}`);
    }
    if (sr.sailNumber) {
      return (name += `${name && ` - `}${sr.sailNumber}`);
    }
    return name;
  };
  return (
    <>
      <div className="mt-3">
        <table className="table table-sm table-striped">
          <thead>
            <tr>
              <td>Rank</td>
              <td>Competitor</td>
              <td className="pts">Pts</td>
            </tr>
          </thead>
          <tbody>
            {results?.map((result, i) => {
              return (
                <tr key={i}>
                  <td>{result.Rank}</td>
                  <td className="competitor">
                    <div className="boat">{getDisplayName(result)}</div>
                    <strong>Helm</strong>:{` ${result?.helmName}`}
                    {result?.crew && (
                      <div
                        style={{
                          fontSize: "13px",
                        }}
                      >
                        <strong>Crew</strong>:{` ${result?.crew}`}
                      </div>
                    )}
                    {result?.clubName && (
                      <div
                        style={{
                          fontSize: "13px",
                        }}
                      >
                        <strong>Club</strong>:{` ${result?.clubName}`}
                      </div>
                    )}
                  </td>
                  <td className="pts">{result.PointsFormatted}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default RaceResultsOneDesignTable;
