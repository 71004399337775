import React, { useState, useEffect } from "react";
import { Nav } from "react-bootstrap";
import { CacheManager } from "../../Services/cache-service";
import moment from "moment";
import MenuIconBar from "./menu.icon.bar";
import { SAILINGLIVE_YEARS } from "../Common/cacheActions";
import marginLogo from "../../Assets/img/margin.png";
import versionInfo from "../../version.json";
import { useUser } from "../../Components/Common/context";
import { Link } from "react-router-dom";

const AppMenu = () => {
  const [lastUpdatedOn, setLastUpdatedOn] = useState("");
  const [expanded, setExpanded] = useState(false);
  const { user } = useUser();

  const toggleMenu = () => {
    setExpanded(!expanded);
    if (expanded) {
      getCacheTime();
    }
  };

  const closeMenu = () => {
    setExpanded(false);
  };

  const getCacheTime = () => {
    CacheManager.getLastUpdatedTimeStamp(SAILINGLIVE_YEARS).then((r) => {
      if (r !== "") {
        setLastUpdatedOn(moment(r).format("DD-MMM-YYYY HH:mm"));
      } else {
        setLastUpdatedOn("now");
      }
    });
  };

  useEffect(() => {
    getCacheTime();
  }, []);

  const lastUpdatedCacheTimeStamp = () => {
    toggleMenu();
    getCacheTime();
  };

  return (
    <div role="button" className="footerMenuOptionContainer">
      <MenuIconBar
        id="sidebar"
        icon="appmenu fas fa-bars"
        expanded={expanded}
        toggle={toggleMenu}
        close={closeMenu}
        dataCy="app-menu-icon"
      >
        <Nav.Link
          rel="noreferrer"
          href="https://www.sailing.ie/"
          target="_blank"
          className="fs-5 appMenuSeparator"
        >
          Irish Sailing Website
        </Nav.Link>
        <Nav.Link
          rel="noreferrer"
          href="https://www.sailing.ie/Shop"
          target="_blank"
          className="fs-5 appMenuSeparator"
        >
          Irish Sailing Shop
        </Nav.Link>
        <Nav.Link
          rel="noreferrer"
          href="https://www.sailing.ie/library"
          target="_blank"
          className="fs-5 appMenuSeparator"
        >
          Irish Sailing Library
        </Nav.Link>
        <Nav.Link
          rel="noreferrer"
          href="https://www.sailing.ie/Training/Find-A-Club-Or-Centre"
          target="_blank"
          className="fs-5 appMenuSeparator"
        >
          Find a Club or Training Centre
        </Nav.Link>
        <Link
          rel="noreferrer"
          to="/feedback"
          className="fs-5 appMenuSeparator nav-link"
        >
          Feedback
        </Link>

        {!user.UserID && (
          <Nav.Link
            rel="noreferrer"
            href="/login"
            className="fs-5 appMenuSeparator"
          >
            Login
          </Nav.Link>
        )}
        {user.UserID && (
          <>
            {(user.IsAdmin || user.IsOrganisation) && (
              <Nav.Link
                rel="noreferrer"
                href="/manage/my-events"
                className="fs-5 appMenuSeparator"
              >
                Manage Events
              </Nav.Link>
            )}
            <Nav.Link
              rel="noreferrer"
              href="/logout"
              className="fs-5 appMenuSeparator"
            >
              Logout
            </Nav.Link>
          </>
        )}

        <Nav.Link
          rel="noreferrer"
          href="https://www.margin.ie"
          target="_blank"
          className="text-end appMenuSeparator mt-4"
        >
          Developed by <img src={marginLogo} height="10" width="47" />
        </Nav.Link>
        <span className="spanVersionNumber mb-2">
          <span className="versionNumber float-left pad-left-10">
            Version: {versionInfo.version + " " + process.env.REACT_APP_TEST}
          </span>
          <span className="versionNumber float-right pad-right-10">
            Last updated on: {lastUpdatedOn}
          </span>
        </span>
      </MenuIconBar>
    </div>
  );
};

export default AppMenu;
