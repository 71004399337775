import React, { useState, useEffect, Fragment } from "react";
import EventResultsFleetItem from "./event.results.fleet.item";

const EventResultsFleetList = ({ eventId, fleets, date, ...props }) => {
  return fleets?.map((fleet) => {
    return (
      <Fragment key={fleet.Id}>
        <EventResultsFleetItem eventId={eventId} fleet={fleet} date={date} />
      </Fragment>
    );
  });
};

export default EventResultsFleetList;
