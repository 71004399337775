import React, { useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import ValidationMessages from "../validation-messages/validation-messages.jsx";

const FormSelectInput = ({
  label,
  register,
  registerOptions,
  name,
  required = false,
  resources,
  inline = false,
  options,
  onChange,
  groupClassName,
  labelClasses,
  errorMessage = null,
  readOnly = false,
  ...props
}) => {
  const [errors, setErrors] = useState(props.errors);

  useEffect(() => {
    if (
      props.defaultValue !== "" &&
      props.defaultValue !== undefined &&
      props.defaultValue !== null
    ) {
      setValue(props.defaultValue);
    }
  }, []);

  useEffect(() => {
    setErrors(props.errors);
  }, [props.errors]);

  const isValid = (errors) => {
    return !errors && (required ? value?.length > 0 : true);
  };
  return (
    <>
      <Form.Group
        controlId={name}
        className={
          inline
            ? `d-flex align-items-baseline ${groupClassName}`
            : groupClassName
        }
      >
        {label && (
          <Form.Label
            className={`${labelClasses ? labelClasses : ""} ${
              inline ? "me-2 col-3" : ""
            }`}
          >
            {label}
          </Form.Label>
        )}
        <Form.Select
          {...register(name, registerOptions)}
          onChange={onChange}
          size="sm"
          disabled={readOnly}
        >
          {options?.map((option) => (
            <option key={option.label} value={option.value}>
              {option.label}
            </option>
          ))}
        </Form.Select>

        {!inline &&
          [props.errors[name]]?.map((err, idx) => {
            return (
              <div key={idx} className="text-danger">
                {ValidationMessages(err, registerOptions, errorMessage)}
              </div>
            );
          })}
      </Form.Group>
      {inline &&
        [errors]?.map((err, idx) => {
          return (
            <div key={idx} className="text-danger">
              {ValidationMessages(err, registerOptions, errorMessage)}
            </div>
          );
        })}
    </>
  );
};

export default FormSelectInput;
