import React, { useState, useEffect, useMemo } from "react";
import MainMenu from "../../Components/Main-Menu/menu.main";
import { toast } from "react-toastify";
import TrainingFilter from "./Components/training.list.filter";
import TrainingCourseList from "./Components/training.course.list";
import TrainingService from "../../Services/training-service";

const TrainingScreen = ({ ...props }) => {
  const [courses, setCourses] = useState(null);

  useEffect(() => {
    TrainingService.getCourses()
      .then((r) => {
        setCourses(r.data.data);
      })
      .catch((error) => {
        console.error(error);
        // Handle any errors here
      });
  }, []);

  return (
    <>
      <MainMenu showBackButon={false} />
      <div className="main-panel">
        <TrainingFilter />
        <div className="training-wrapper col-12">
          <TrainingCourseList courses={courses} />
        </div>
      </div>
    </>
  );
};

export default TrainingScreen;
