import { get } from "./http-instance";

export default class TrainingService {
  static async getCourses() {
    return get("App/Courses");
  }

  static async getCourse(courseDateId) {
    return get("App/GetCourse", { courseDateId });
  }
}
