export default class DNNUserModel {
  constructor(props) {
    this.UserID = props?.UserID || 0;
    this.Username = props?.Username || "";
    this.FirstName = props?.FirstName || "";
    this.LastName = props?.LastName || "";
    this.DisplayName = props?.DisplayName || "";
    this.Roles = props?.Roles || [];
    this.OrganisationName = props?.OrganisationName || "";
    this.Address = props?.Address || "";
    this.Town = props?.Town || "";
    this.EIRCode = props?.EIRCode || "";
    this.County = props?.County || "";
    this.IsAdmin = props?.Roles?.includes("ISL Admin") || false;
    this.IsOrganisation = props?.IsOrganisation || false;
    this.IsLogged = (props?.UserID || 0) != 0;
    this.OrganisationId = props?.OrganisationId || 0;
    this.PersonId = props?.PersonId || 0;
    this.OrganisationList = props?.OrganisationList || null;
  }
}
