const moment = require("moment");
import { AppSettingService } from "./app-setting-service.jsx";

const cacheName = "sailingLive-cache";
// let timeout =    process.env.REACT_APP_CACHE_TIMEOUT;
let timeout = AppSettingService.getCacheTimeOut();
export const CacheManager = {
  setCache,
  validateCacheWrapper,
  validateCache,
  getLastUpdatedTimeStamp,
  isCacheExpired,
  addMinutes,
  readCache,
  deleteAndAdd,
  deleteCache,
  setNewCache,
};

function setCache(key, data) {
  //CommonService.addLog("setCache", `CacheManager: update cache starts`);
  if ("caches" in window) {
    let dateTimeNow = moment().format();
    //CommonService.addLog("SetCache",`Update cache for ${key}. Timestamp: ${dateTimeNow}`);
    let blob = new Blob([JSON.stringify(data, null, 2)], {
      type: "application/json",
    });
    let init = { status: 200, statusText: dateTimeNow };
    var res = new Response(blob, init);

    return caches.open(cacheName).then((cache) => {
      cache.put(key, res).then(function (q) {
        return q;
      });
    });
  }
}

async function deleteCache(key) {
  if ("caches" in window) {
    const stat = await caches.open(cacheName).then((cache) => {
      cache.delete(key).then(function (res) {
        return true;
      });
    });
    return stat;
  }
}

function setNewCache(key, data) {
  //CommonService.addLog("setNewCache",`CacheManager: remove and add cache starts`);
  (async () => {
    let deleteStatus = await deleteCache(key);
    //CommonService.addLog("setNewCache", `CacheManager: cache removed`);
    let addStatus = await setCache(key, data);
    //CommonService.addLog("setNewCache",`CacheManager: set new cache successfully`);
  })();
}

async function validateCacheWrapper(key) {
  await validateCache(key).then(function (r) {
    Promise.resolve("cc");
    return "rr";
  });
}
async function validateCache(key) {
  //CommonService.addLog("validateCache",`CacheManager: validate Cache  starts for ${key}.`);
  let isExpired = false;
  return await readCache(key).then(function (r) {
    if (r != null || r != undefined) {
      let t = r.json();
      let cacheTime = moment(r.data.statusText).toDate();
      //CommonService.addLog("validateCache",`CacheManager: Last updated cache time for ${key} is ${cacheTime}.`);
      isExpired = isCacheExpired(cacheTime);

      //CommonService.addLog("validateCache",`CacheManager:Is cache Expire for Cache  ${key}: ${isExpired}.`);
      return isExpired;
    } else {
      return true;
    }
  });
}

async function getLastUpdatedTimeStamp(key) {
  //CommonService.addLog("getLastUpdatedTimeStamp",`CacheManager: Get Last Updated Time Stamp starts`);
  return await readCache(key).then(function (r) {
    if (r != null || r != undefined) {
      let t = r.json();
      let cacheTime = moment(r.data.statusText).toDate();
      //CommonService.addLog("getLastUpdatedTimeStamp",`CacheManager: Last Updated Time Stamp: ${cacheTime}`);
      if (cacheTime) {
        return cacheTime;
      } else {
        return "";
      }
    } else {
      return "";
    }
  });
}

function isCacheExpired(cacheTime) {
  //CommonService.addLog("isCacheExpired", `CacheManager: isCacheExpired starts`);
  let dtNow = new Date();
  //console.log("dtNow", timeout);
  let cacheExpireTime = addMinutes(cacheTime, timeout);

  let now = addMinutes(dtNow, 1);
  let t = {
    currentTime: now,
    cacheExpireOn: cacheExpireTime,
    isCacheExpired: new Date(now) > new Date(cacheExpireTime),
  };
  //CommonService.addLog("isCacheExpired",` CacheManager: isCacheExpired details  ${JSON.stringify(t)}`);

  return new Date(now) > new Date(cacheExpireTime);
}

function addMinutes(date, minutes) {
  var newDateObj = moment(date).add(minutes, "m").toDate();
  return newDateObj;
}

function readCache(key) {
  //CommonService.addLog("ReadCache",`CacheManager: Read cache starts for ${key} `);
  if ("caches" in window) {
    return caches.open(cacheName).then((cache) => {
      return cache.match(key).then((item) => {
        return item;
      });
    });
  } else return new Promise(() => {});
}

function deleteAndAdd(key, data) {
  return this.delete(key).then((response) => {
    var blob = new Blob([JSON.stringify(data, null, 2)], {
      type: "application/json",
    });

    var init = { status: 200, statusText: "sucess" };
    var res = new Response(blob, init);

    if ("caches" in window) {
      return caches.open(cacheName).then((cache) => {
        cache.put(key, res).then(function (q) {
          return q;
        });
      });
    }
  });
}
