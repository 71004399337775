import { get } from "./http-instance";

export default class EventService {
  static async getYears() {
    return get("App/Years");
  }

  static async getEventsByYear(year) {
    return get("App/Dates", { year });
  }

  static async getEventsByDate(date) {
    return get("App/Events", { date });
  }

  static async getEventsBySearchFilters(filters) {
    return get("App/EventsByFilter", filters);
  }

  static async getEventDetailsByDate(eventId, date) {
    return get("App/EventDetails", { eventId, date });
  }

  static async eventDetailsAndSeries(eventId, fleetId) {
    return get("App/EventDetailsAndSeries", { eventId, fleetId });
  }

  static async getCompetitorResults(competitorId, seriesId) {
    return get("App/CompetitorResults", { competitorId, seriesId });
  }

  static async getRaceResults(raceId) {
    return get("App/Race", { raceId });
  }

  static async getCalendar(_from, page, pageSize) {
    return get("App/Calendar", { _from, page, pageSize });
  }

  static async getOrganisationLogo(eventId) {
    return get("App/GetOrganisationLogo", { eventId });
  }

  static async getEventDetailsAndSeries(eventId, date, fleetId) {
    return get("App/EventDetailsAndSeries", { eventId, date, fleetId });
  }

  static async getFleets(eventId, date) {
    return get("App/EventFleets", { eventId, date });
  }
}
