import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import ValidationMessages from "../validation-messages/validation-messages.jsx";

const FormTextareaInput = ({
  label,
  register,
  registerOptions,
  name,
  onChange,
  required = false,
  rows = 3,
  resources,
  inline,
  groupClassName,
  placeholder,
  labelClasses,
  readOnly = false,
  ...props
}) => {
  const [errors, setErrors] = useState(props.errors);
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setErrors(props.errors);
  }, [props.errors]);

  const isValid = (errors) => {
    return !errors && (required ? value?.length > 0 : true);
  };

  return (
    <>
      <Form.Group
        controlId={name}
        hidden={props.hidden}
        className={
          inline
            ? `bg-transparent border-0 d-flex align-items-baseline ${groupClassName} `
            : `bg-transparent border-0 ${groupClassName} `
        }
      >
        {label && (
          <Form.Label
            className={`${labelClasses ? labelClasses : ""} ${
              inline ? "me-2 col-3" : ""
            }`}
          >
            {label}
          </Form.Label>
        )}
        <Form.Control
          as="textarea"
          rows={rows}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          {...register(name, registerOptions)}
          {...props}
          disabled={readOnly}
        />

        {!inline &&
          [errors[name]]?.map((err, idx) => {
            return (
              <div key={idx} className="text-danger">
                {ValidationMessages(err, registerOptions)}
              </div>
            );
          })}
      </Form.Group>
      {inline &&
        [errors]?.map((err, idx) => {
          return (
            <div key={idx} className="text-danger">
              {ValidationMessages(err, registerOptions)}
            </div>
          );
        })}
    </>
  );
};

export default FormTextareaInput;
