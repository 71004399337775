import React, { useEffect, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";

const NewsListItem = ({ newsItem, ...props }) => {
  if (!newsItem) return null;
  const [date, setDate] = useState(moment(newsItem.Date).format("D MMM YYYY"));

  const ArticleImage = () => {
    if (!newsItem.Image) return null;
    var url = ``;
    // if (newsItem.Image.startsWith("/Portals")) {
    //   url = `${process.env.REACT_APP_NEWS_BASEURL}${newsItem.Image}`;
    // } else {
    //   url = `${newsItem.Image}`;
    // }
    // //return <img src={`${url}`} className="" />;

    // url.replace(
    //   process.env.REACT_APP_NEWS_BASEURL,
    //   `${process.env.REACT_APP_NEWS_BASEURL}`
    // );
    return (
      <img
        src={`${process.env.REACT_APP_NEWS_BASEURL}/dnnimagehandler.ashx?mode=file&file=${newsItem.Image}&w=160&h=90&resizemode=crop`}
        className=""
      />
    );
  };

  return (
    <Link to={`${newsItem.ContentId}`} key={props.key}>
      <div className="news-item">
        <div className="news-item-header col-4 col-md-1">
          <div className="news-item-image">
            <ArticleImage />
          </div>
          {/* <div className="news-item-category">
            <span>{newsItem.Category?.toUpperCase()}</span>
          </div> */}
        </div>
        <div className="news-item-body col-8 col-md-11">
          <div className="news-item-title">{newsItem.Title}</div>
          <div className="news-item-date">{date}</div>
          <div className="news-item-category">{newsItem.Category}</div>
        </div>
      </div>
    </Link>
  );
};

export default React.memo(NewsListItem);
