import React, { useState, useEffect, useMemo } from "react";

const PasswordRecoveryInfo = ({ ...props }) => {
  const [showPrompt, setShowPrompt] = useState(false);
  return (
    <>
      <div className="text-center text-dark mt-2">
        <span onClick={() => setShowPrompt(!showPrompt)}>
          <span>Forgot Your Password?</span>
          <i className="ms-2 fas fa-info info-icon"></i>
        </span>
        <br />
        {showPrompt && (
          <div className="alert">
            To reset your password, please go to
            <br></br>
            <a href="https://www.sailing.ie/login" target="_blank">
              www.sailing.ie
            </a>
            <br></br>
            and use the password reset option
          </div>
        )}
        <br />
        <div className="alert alert-info">
          If you don't have an ISL account go to
          <br />
          <a
            href="https://www.sailing.ie/New-User-Registration"
            target="_blank"
          >
            www.sailing.ie
          </a>
          <br />
          to create your new account
        </div>
        <br />
      </div>
    </>
  );
};

export default PasswordRecoveryInfo;
