import React, { useState, useEffect } from "react";

const MyEventsRaceCommittee = ({
  raceCommittee,
  setRaceCommitee,
  readOnly = false,
}) => {
  const model = raceCommittee;
  const handleCommitteeChange = (e) => {
    const { name, value } = e.target;
    const newModel = raceCommittee.map((m) => {
      if (m.Role === name) {
        m.Requirement = value;
      }
      return m;
    });
    setRaceCommitee(newModel);
  };

  return (
    <>
      <div className="col-12 px-1">
        <div className="col-12 fw-bold mb-2">Race committee</div>
        <div className="col-12">
          {raceCommittee.map((m, i) => {
            return (
              <div
                key={i}
                className="px-2 col-12 d-flex align-items-center mb-2"
              >
                <div className="col-7">{m.Role}</div>
                <div className="col-5">
                  <select
                    className="col-12 form-select form-select-sm"
                    value={m.Requirement}
                    name={m.Role}
                    onChange={handleCommitteeChange}
                    disabled={readOnly}
                  >
                    <option value="Not sure / Not needed">
                      Not sure / Not needed
                    </option>
                    <option value="Have one">Have one</option>
                    <option value="Need one">Need one</option>
                  </select>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default MyEventsRaceCommittee;
