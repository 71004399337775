import React, { useEffect, useState } from "react";

const TrainingFilter = ({ text = " Training", ...props }) => {
  return (
    <div className="training-menu col-12">
      <div className="training-menu-title-container">
        <i className={`fas fa-graduation-cap fa-2x me-2 `} />{" "}
        <div className="training-menu-title">{text}</div>
      </div>
      <div></div>
    </div>
  );
};

export default TrainingFilter;
