import React, { Suspense } from "react";
import "../../Assets/css/main/style.main.scss";
const Footer = React.lazy(() => import("../../Components/Footer/footer.main"));

const MainLayout = ({ children }) => {
  const isLoginScreen = window.location.pathname === "/login";
  return (
    <div className="wrapper">
      <Suspense className="main-panel">
        {children}

        {!isLoginScreen && <Footer />}
      </Suspense>
    </div>
  );
};

export default MainLayout;
