import React, { useState, useEffect } from "react";
import margin from "../../../Assets/img/margin.png";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Util from "../../../Components/Common/util";
//import { useNavigate, useParams } from "react-router-dom";

const EventResultsHeader = ({ event, date, fleetId, series, ...props }) => {
  const fleet = event?.Fleets?.find((f) => f.Id == fleetId);
  const navigate = useNavigate();

  const navigateToSeries = (serieId) => {
    navigate(`/event-results/${event.Id}/${date}/${fleetId}/${serieId}`);
  };
  const encodeClubVenue = (clubVenue) => {
    //return return encodeURIComponent(clubVenue) if any of address, town, county, or eircode is null
    if (
      event?.Address === null ||
      event?.Town === null ||
      event?.County === null ||
      event?.Eircode === null
    ) {
      return encodeURIComponent(clubVenue);
    }
    //make encoded uri component for google maps using address, town, county, and eircode
    let string = `${event?.Address}, ${event?.Town}, ${event?.County}, ${event?.Eircode}`;
    return encodeURIComponent(string);
  };

  const AddressData = () => {
    var address = event?.Address;
    var town = event?.Town;
    var county = event?.County;
    var eircode = event?.Eircode;

    //address, town, county, and eircode can be null.
    //show the data as Address, Town, County, Eircode
    var addressData = "";
    if (address) {
      addressData += `${address}, `;
    }
    if (town) {
      addressData += `${town}, `;
    }
    if (county) {
      addressData += `${county}, `;
    }
    if (eircode) {
      addressData += `${eircode}`;
    }

    if (addressData === "") {
      return null;
    }

    // get a encoded google maps query
    var encodedAddress = encodeClubVenue(addressData);
    return (
      <>
        {addressData}
        <a
          href={`https://www.google.com/maps/search/?api=1&query=c${encodedAddress}`}
          target="_blank"
          className="ms-2"
        >
          <i className="fas fa-map-marker-alt fa-2x" />
        </a>
      </>
    );
  };

  const ShowLogo = () => {
    if (event.EventLogoURL) {
      return <img src={event.EventLogoURL} className="EventLogo" />;
    }
    if (event.SponsorLogoURL) {
      return (
        <div className="m-2 d-flex justify-content-center">
          <img src={event.SponsorLogoURL} className="EventLogo" />
        </div>
      );
    }
    if (event.OrganisationLogo) {
      return (
        <img
          src={`
  data:image/png;base64,${event.OrganisationLogo}
  `}
          className="EventLogo"
        />
      );
    }
    return <></>;
  };

  if (!event) return <></>;
  return (
    <>
      <div className="EventResultsHeader">
        <div className="mb-1">
          <div className="EventResultsHeaderEventName">{event?.EventName}</div>
          <div className="col-12 d-flex align-items-start">
            <div className="col-9  col-md-11">
              <div className="EventResultsHeaderEventName">
                {fleet?.FleetName}
              </div>
              <div className="EventResultsHeaderEventDates mt-1">
                <i className="fas fa-calendar ms-1 me-2" />
                {Util.formatSingleDates(event?.Dates)}
              </div>
              {event?.Name && (
                <div className="EventResultsHeaderEventClub mt-1 ps-4 fw-bold">
                  {event?.EventName}
                </div>
              )}
              <div className="EventResultsHeaderEventClub mt-1 ps-4 fw-bold">
                {event?.ClubVenue}
              </div>
              <div
                className="EventResultsHeaderEventClub mt-1 ms-4 fw-bold"
                hidden={!AddressData()}
              >
                {AddressData()}
              </div>
              <div
                className="EventDetailsHeaderEventClub mt-1 fw-bold"
                hidden={!event.OrganiserEmail}
              >
                <i className="fas fa-fw ms-1 me-2" />
                {event.OrganiserEmail}
              </div>
              <div
                className="calendar-list-item-event-website"
                hidden={!event.EventWebsite}
              >
                <a
                  href={Util.httpsLink(event.EventWebsite)}
                  target="_blank"
                  className="col-12 d-flex"
                >
                  <div className="col-1">
                    <i className="fas fa-link" />
                  </div>
                  <div className="col-11">Organiser website</div>
                </a>
              </div>
            </div>

            <div className="col-3 col-md-1 ps-0 d-flex align-items-start">
              <ShowLogo />
            </div>
          </div>
        </div>
        {series?.length > 1 ? (
          <div className="mb-3 d-grid">
            {series.map((serie, index) => {
              return (
                <div
                  onClick={() => navigateToSeries(serie.Id)}
                  className=" mx-4 mt-2 p-2 py-1 fw-bold text-white btn btn-primary text-start"
                  key={index}
                >
                  <i className="fas fa-arrow-right me-2" />
                  {serie.name || serie.Name}
                </div>
              );
            })}
          </div>
        ) : (
          <></>
        )}
        <div className="fw-bold fst-italic me-1 d-flex justify-content-end">
          Results by {event?.ResultsFrom} - Last updated{" "}
          {moment(event?.LastUpdatedDate).format("D-MMM-YYYY H:mm")}
        </div>
      </div>
    </>
  );
};

export default EventResultsHeader;
