import React, { useEffect, useState } from "react";

const CalendarFilter = ({ onFilterSelected, selectedFilter }) => {
  const [show, setShow] = useState(true);

  return (
    <>
      <div className="d-flex fs-6">
        <div
          role="button"
          className={`calendar-filter-item  ${
            selectedFilter == "future" ? "fw-bold" : ""
          }`}
          onClick={() => onFilterSelected("future")}
        >
          Upcoming
        </div>
        <div className="mx-2">|</div>
        <div
          role="button"
          className={`calendar-filter-item  ${
            selectedFilter == "past" ? "fw-bold" : ""
          }`}
          onClick={() => onFilterSelected("past")}
        >
          Recent
        </div>
      </div>
    </>
  );
};

export default CalendarFilter;
