import React from "react";
import { createRoot } from "react-dom/client";
import * as serviceWorker from "./service-worker.js";
import swConfig from "./swConfig.js";
import App from "./App.jsx";
import Sandbox from "./Sandbox.jsx";
import { ContextWrapper } from "./Components/Common/context.jsx";
import { BrowserRouter } from "react-router-dom";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <ContextWrapper>
      <App />
    </ContextWrapper>
  </BrowserRouter>
);
//root.render(<Sandbox />);

serviceWorker.register(swConfig);
