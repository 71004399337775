import React, { useState, useEffect, useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import WideViewTable from "../../WideView/components/wideView.table";
import RaceList from "../../../Components/Common/RaceList/racelist";
import SeriesHeader from "../../../Components/Common/SeriesHeader/series.header";
import EventDetailsSeriesListItemSeriesResultsList from "./event.details.series.list.item.seriesresults.list";
import RaceDetails from "../../RaceDetails/race.details.screen";
import RaceResultsOneDesignTable from "../../RaceDetails/Components/race.onedesign.table";
import RaceResultsHandicappedTable from "../../RaceDetails/Components/race.handicapped.table";
import moment from "moment";
import EventService from "../../../Services/event-service";

const SeriesDetails = ({ series, event, isSearch = false }) => {
  const [viewMode, setViewMode] = useState("Overall");
  const eventId = event.eventData?.Id || useParams().id;
  const seriesId = series.Id || useParams().seriesId;
  const [raceResults, setRaceResults] = useState();
  const [raceId, setRaceId] = useState(useParams().raceId || null);
  const [race, setRace] = useState();
  const races = series.Races;

  useLayoutEffect(() => {
    if (!raceId || !event) return;

    //is this raceId inside the series.Races?
    if (!races.find((r) => r.Id == raceId)) return;

    setViewMode("RaceDetails");
    setRace(races.find((r) => r.Id == raceId));
    // var raceResults = [...event.series]?.reduce((acc, _series) => {
    //   const race = _series?.Races?.find((race) => race.Id === raceId);
    //   //if acc is not an array, make it an array
    //   if (!Array.isArray(acc)) acc = [];
    //   if (race) {
    //     acc.push(...race.raceResults);
    //   }

    //   return acc;
    // }, []);

    //setRaceResults(raceResults);
    // EventService.getRaceResults(raceId, (response) => {
    //   setRaceResults(response.data.results);
    //   console.log(response.data.results);
    // });
  }, [raceId, event]);

  useEffect(() => {
    if (!race) return;
    setRaceResults(race.raceResults);
  }, [race]);

  const onOverallClick = () => {
    setViewMode("Overall");
  };
  const onWideClick = () => {
    setViewMode("WideView");
  };
  const onRaceListItemClick = (raceId) => {
    setRaceId(raceId);

    setViewMode("RaceDetails");
  };

  const raceDetails = () => {
    if (!race) return <></>;
    let raceDate = moment(race.RaceDate).format("D-MMMM-YYYY");
    let raceNumber = series?.Races?.findIndex((r) => r.Id == raceId) + 1;

    return (
      <div className="px-2">
        <div className="fs-5 fw-bold">
          Race {raceNumber} - {raceDate}
        </div>

        <div className="">
          {series?.RaceType == "OneDesign" && (
            <>
              <RaceResultsOneDesignTable results={raceResults} />
            </>
          )}
          {series?.RaceType == "Handicap" && (
            <>
              <RaceResultsHandicappedTable results={raceResults} />
            </>
          )}
        </div>
      </div>
    );
  };

  return !event ? (
    <></>
  ) : (
    <div id={`_series-${seriesId}`}>
      <div id={`series-${seriesId}`} className="scrollTop">
        <SeriesHeader series={series} />
      </div>

      <RaceList
        eventId={eventId}
        seriesId={seriesId}
        races={races}
        onOverallClick={onOverallClick}
        onWideClick={onWideClick}
        onRaceClick={onRaceListItemClick}
        selectedRaceId={raceId}
        selectedMode={viewMode}
      />

      {viewMode == "Overall" && (
        <EventDetailsSeriesListItemSeriesResultsList series={series} />
      )}
      {viewMode == "WideView" && <WideViewTable series={series} />}
      {viewMode == "RaceDetails" && raceResults && raceDetails()}
    </div>
  );
};

export default SeriesDetails;
