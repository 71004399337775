import React, { useState, useEffect } from "react";
import CompetitorsSearchFilters from "./competitors.filters";
import EventsSearchFilters from "./events.filters";
import EventsFiltersModel from "../Models/events.filters.model";
import CompetitorsFiltersModel from "../Models/competitors.filters.model";
import EventService from "../../../Services/event-service";
import StorageService from "../../../Services/storage-service";

const FilterTabs = ({ onResults }) => {
  const [eventsFilters, setEventsFilters] = useState(new EventsFiltersModel());
  const [competitorsFilters, setCompetitorsFilters] = useState(
    new CompetitorsFiltersModel()
  );
  const [activeFilter, setActiveFilter] = useState(eventsFilters);
  const [selectedTab, setSelectedTab] = useState("Events");

  useEffect(() => {
    let isSaved = StorageService.get("SAILINGLIVE_LASTSEARCH");
    if (!isSaved) return;
    var _savedSearch = JSON.parse(StorageService.get("SAILINGLIVE_LASTSEARCH"));
    if (!_savedSearch) return;
    //console.log(_savedSearch);
    setSelectedTab(_savedSearch.tab);
    if (_savedSearch.tab == "Events") {
      setEventsFilters(_savedSearch.filter);
    }
    if (_savedSearch.tab == "Competitors") {
      setCompetitorsFilters(_savedSearch.filter);
    }
    setActiveFilter(_savedSearch.filter);
  }, []);

  const handleUpdateFilter = (filter) => {
    if (selectedTab == "Events") {
      setEventsFilters(filter);
    } else {
      setCompetitorsFilters(filter);
    }
    setActiveFilter(filter);
  };
  const handleSearch = (filter) => {
    if (filter.Date) {
      filter.Date = new Date(filter.Date).toISOString();
    }
    StorageService.set("SAILINGLIVE_LASTSEARCH", JSON.stringify(filter));
    EventService.getEventsBySearchFilters(filter.filter)
      .then((response) => {
        onResults(response.data.events);
      })
      .catch((error) => {
        console.error(error);
        // Handle any errors here
      });
  };
  return (
    <>
      <div className="SearchFilterTabs">
        <div className="tabSelector col-12 p-0 d-flex justify-content-center mb-3">
          <div
            role="button"
            className={`tabOption col-6 ${
              selectedTab == "Events" ? "active" : ""
            }`}
            onClick={() => {
              setSelectedTab("Events");
              setActiveFilter(eventsFilters);
              onResults(null);
            }}
          >
            Events
          </div>
          <div
            role="button"
            className={`tabOption col-6 ${
              selectedTab == "Competitors" ? "active" : ""
            }`}
            onClick={() => {
              setSelectedTab("Competitors");
              setActiveFilter(competitorsFilters);
              onResults(null);
            }}
          >
            Competitors
          </div>
        </div>
        <div className="selectedTab">
          {selectedTab == "Events" ? (
            <EventsSearchFilters
              activeFilter={eventsFilters}
              onUpdateFilter={(filter) => handleUpdateFilter(filter)}
              onSubmitFilter={(filter) => handleSearch(filter)}
            />
          ) : (
            <CompetitorsSearchFilters
              activeFilter={competitorsFilters}
              onUpdateFilter={(filter) => handleUpdateFilter(filter)}
              onSubmitFilter={(filter) => handleSearch(filter)}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default FilterTabs;
