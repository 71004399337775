import { useContext, useState, useEffect, createContext } from "react";
import ContextService from "../../Services/context-service";
import DNNUserModel from "../../Models/user.model.jsx";
import { useNavigate } from "react-router-dom";
import Utils from "./util.jsx";

export const UserContext = createContext();
export const useUser = () => useContext(UserContext);

export const RefreshUserContext = () => {
  const newEvent = new CustomEvent("refreshUserContext", {
    detail: { userIsValid: "OK" },
  });
  window.dispatchEvent(newEvent);
};

export const ContextWrapper = ({ children }) => {
  const [user, setUser] = useState(new DNNUserModel());

  const navigate = useNavigate();

  useEffect(() => {
    getUserContext();
    window.addEventListener("refreshUserContext", getUserContext);
    return () => {
      window.removeEventListener("refreshUserContext", getUserContext);
    };
  }, []);

  const getUserContext = () => {
    let existsToken = Utils.isDefined(localStorage.getItem("token"));
    if (window.location.href.includes("/login")) return;
    if (!existsToken) return;

    //console.log("Getting context");
    ContextService.getUserContext()
      .then((userContext) => {
        if (Utils.undefined(userContext)) {
          return;
        }

        var model = new DNNUserModel(userContext.data.ctx);
        //console.log("User context", model);
        setUser(model);
      })
      .catch((e) => {
        console.error("Error getting user context", e);
      });
  };

  return (
    <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
  );
};
