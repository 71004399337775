import React, { useState, useEffect, useLayoutEffect } from "react";

const WideViewTableHeader = ({ event, series, fleetId }) => {
  const getRaceTypeHeaderCells = () => {
    let type = series?.RaceType;
    if (type == "Handicap") {
      return (
        <>
          <th className="text-center">Start HC</th>
          <th className="text-center">Final HC</th>
        </>
      );
    }
    return <></>;
  };
  const getRaceResultsHeaderCells = () => {
    return (
      <>
        {series?.Races?.map((r, i) => {
          return <th key={i} className="text-center">{`R${r.Rank}`}</th>;
        })}
      </>
    );
  };
  return (
    <thead>
      <tr className="WideTableHeader sticky">
        <th className="text-center" style={{ width: "36px" }}>
          Rank
        </th>
        <th className="text-start" style={{ width: "95px" }}>
          Boat
        </th>
        <th className="text-start" style={{ width: "200px" }}>
          Competitor
        </th>
        {getRaceTypeHeaderCells()}
        {getRaceResultsHeaderCells()}
        <th className="text-center">Total</th>
        <th className="text-center">Net</th>
      </tr>
    </thead>
  );
};

export default WideViewTableHeader;
