import React from "react";

const SeriesHeader = ({ series }) => {
  const Name = (props) => {
    if (!series?.Name) return <></>;
    return (
      <div className="px-3 SeriesName fs-5">
        <div className="d-flex justify-content-between">
          <div>{series?.Name}</div>
          <div className="provisional">Provisional</div>
        </div>
      </div>
    );
  };
  const RatingSystem = (props) => {
    if (!series?.RatingSystem) return <></>;
    return (
      <>
        <strong>Rating System</strong>: {series?.RatingSystem}
      </>
    );
  };
  const Entries = (props) => {
    if (!series?.SeriesResults?.length) return <></>;
    return (
      <>
        <strong>Entries</strong>: {series?.SeriesResults?.length}
      </>
    );
  };
  const ScoringSystem = (props) => {
    if (!series?.ScoringSystem) return <></>;
    return (
      <>
        <strong>Scoring System</strong>: {series?.ScoringSystem}
      </>
    );
  };
  return (
    <>
      <div className="col-12 SeriesHeader">
        <Name />
        <div className="d-flex px-3 mt-2 justify-content-between fs-6">
          <div className="col-auto">
            <RatingSystem />
          </div>
          <div className="col-auto">
            <Entries />
          </div>
        </div>
        <div className="px-4 d-flex">
          <ScoringSystem />
        </div>
      </div>
    </>
  );
};

export default SeriesHeader;
