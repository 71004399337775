export default {
  onUpdate: (registration) => {
    registration.unregister().then(() => {
      //window.location.reload(true)
    });
  },
  onSuccess: (registration) => {
    console.info("service worker on success state");
  },
};
