// A javascript class with local storage helper methods.

export default class StorageService {
  static remove(key) {
    localStorage.removeItem(key);
  }

  static get(key) {
    let keytimestamp = key + "_timestamp";
    if (this.validateKey(key)) {
      return localStorage.getItem(key);
    } else {
      this.remove(key);
      this.remove(keytimestamp);
      return null;
    }
  }

  static validateKey(key) {
    let timeout = process.env.REACT_APP_CACHE_TIMEOUT;
    let keytimestamp = key + "_timestamp";
    let timestamp = localStorage.getItem(keytimestamp);
    if (timestamp) {
      let now = new Date().getTime();
      if (now - timestamp > timeout * 60000) {
        return false;
      }
    }
    return true;
  }

  static set(key, value) {
    let timestamp = new Date().getTime();
    localStorage.setItem(key, value);
    localStorage.setItem(key + "_timestamp", timestamp);
  }

  static clear() {
    localStorage.clear();
  }
}
