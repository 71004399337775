import React, { useEffect, useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";
const moment = require("moment");
import EventService from "../../Services/event-service";
import RaceResultsHandicappedTable from "./Components/race.handicapped.table";
import SeriesHeader from "../../Components/Common/SeriesHeader/series.header";
import RaceList from "../../Components/Common/RaceList/racelist";
import RaceResultsOneDesignTable from "./Components/race.onedesign.table";

const RaceDetails = ({
  event,
  eventId,
  seriesId,
  raceId,
  fleetId,
  ...props
}) => {
  const [_event, setEvent] = useState();
  const [series, setSeries] = useState();
  const [race, setRace] = useState();
  const [raceNumber, setRaceNumber] = useState();
  const [raceDate, setRaceDate] = useState();
  const [raceResults, setRaceResults] = useState();

  // const { id: eventId, seriesId, raceId, fleetId } = useParams();

  useLayoutEffect(() => {
    return;
    if (!eventId || !seriesId || !raceId) return;
    if (!event) {
      // We did not came here from the event details screen, but from a direct link/url
      getEventDetails();
      return;
    }
    //If we came here from the event details screen, we already have the event data,
    //so there is no need to fetch it again and therefore causing a flicker
    // const series = event.series.find((s) => s.Id == seriesId);
    // setSeries(series);
    // const race = series.Races.find((r) => r.Id == raceId);
    // setRace(race);
    setSeriesAndRace(event);
  }, [eventId]);

  useLayoutEffect(() => {
    if (!series) return;
    var _race = series.Races.find((r) => r.Id == raceId);
    setRace(_race);
  }, [raceId]);

  useLayoutEffect(() => {
    if (!series || !race) return;
    var raceIndex = series?.Races?.findIndex((r) => r.Id == raceId) + 1;
    setRaceNumber(raceIndex);
    var _raceDate = moment(race.RaceDate).format("D-MMMM-YYYY");
    setRaceDate(_raceDate);
    getRaceResults();
  }, [race]);

  const getEventDetails = () => {
    EventService.getEventDetailsByFleet(fleetId)
      .then((response) => {
        setEvent(response);
        setSeriesAndRace(response);
        //   const series = response.series.find((s) => s.Id == seriesId);
        //   setSeries(series);
        //   const race = series.Races.find((r) => r.Id == raceId);
        //   setRace(race);
      })
      .catch((error) => {
        console.error(error);
        // Handle any errors here
      });
  };

  const getRaceResults = () => {
    EventService.getRaceResults(raceId)
      .then((response) => {
        setRaceResults(response.data.results);
      })
      .catch((error) => {
        console.error(error);
        // Handle any errors here
      });
  };

  const setSeriesAndRace = (data) => {
    const series = data.series.find((s) => s.Id == seriesId);
    setSeries(series);
    const race = series.Races.find((r) => r.Id == raceId);
    setRace(race);
  };

  // const onBackButton = () => {
  //   navigate(`/event/${eventId}`);
  // };
  return (
    <>
      <SeriesHeader series={series} />
      <RaceList
        eventId={event?.eventData?.Id || eventId}
        seriesId={series?.Id}
        races={series?.Races}
      />
      <div className="px-2">
        <div className="fs-5 fw-bold">
          Race {raceNumber} - {raceDate}
        </div>

        <div className="">
          {series?.RaceType == "OneDesign" && (
            <>
              <RaceResultsOneDesignTable results={raceResults} />
            </>
          )}
          {series?.RaceType == "Handicap" && (
            <>
              <RaceResultsHandicappedTable results={raceResults} />
            </>
          )}
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default RaceDetails;
