const EXPIRATION_TIME_MS = 24 * 60 * 60 * 1000;
const SOFTER_EXPIRATION_TIME_MS = 5 * 60 * 1000;

const LogoService = {
  setLogo(eventId, logoUrl, expirationTime = EXPIRATION_TIME_MS) {
    const expirationDate = logoUrl
      ? new Date().getTime() + expirationTime
      : new Date().getTime() + SOFTER_EXPIRATION_TIME_MS;
    let eventLogos =
      JSON.parse(localStorage.getItem("SAILINGLIVE_Event_Logos")) || {};
    eventLogos[eventId] = { logoUrl, expirationDate };
    localStorage.setItem("SAILINGLIVE_Event_Logos", JSON.stringify(eventLogos));
  },

  getLogo(eventId) {
    let eventLogos =
      JSON.parse(localStorage.getItem("SAILINGLIVE_Event_Logos")) || {};
    const eventLogoData = eventLogos[eventId];

    if (eventLogoData) {
      const currentTime = new Date().getTime();
      if (currentTime < eventLogoData.expirationDate) {
        return eventLogoData.logoUrl;
      } else {
        delete eventLogos[eventId];
        localStorage.setItem(
          "SAILINGLIVE_Event_Logos",
          JSON.stringify(eventLogos)
        );
      }
    } else {
      return undefined;
    }

    return null;
  },
};

export default LogoService;
