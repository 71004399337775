import React, { Component, Children, isValidElement } from "react";
import PropTypes from "prop-types";

export default class MenuIconBar extends Component {
  constructor(props) {
    super(props);
    this.handler = null;
  }

  componentDidMount() {
    this.handler = document.addEventListener("click", (event) => {
      if (!event.target.closest("#container-" + this.props.id)) {
        this.props.close();
      }
    });
  }

  componentWillUnmount() {
    if (this.handler) this.handler.unbind();

    document.removeEventListener("click", this.handler);
  }

  render() {
    let icon = this.props.icon;
    if (this.props.expanded) {
      icon = "fa fa-times";
    }
    return (
      <React.Fragment>
        <div id={"container-" + this.props.id}>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target={"#" + this.props.id}
            aria-controls={this.props.id}
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={this.props.toggle}
            data-cy={this.props.dataCy}
          >
            <i className={icon}></i>
          </button>

          <div
            className={
              "collapse navbar-collapse shadow " +
              (this.props.expanded ? " show" : "")
            }
            id={this.props.id}
          >
            <ul className="navbar-nav mr-auto">
              {Children.map(this.props.children, (child) => {
                if (isValidElement(child)) {
                  return <li>{child}</li>;
                }
              })}
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
MenuIconBar.propTypes = {
  expanded: PropTypes.bool.isRequired,
  icon: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};
