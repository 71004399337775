import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Util from "../../../Components/Common/util";
import EventService from "../../../Services/event-service";
import LogoService from "../../../Services/logoService";

const CalendarScheduleItem = ({ event, ...props }) => {
  const navigate = useNavigate();
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    if (event.EventLogoURL) {
      setLogo(<img src={event.EventLogoURL} className="EventLogo" />);
    } else if (event.SponsorLogoURL) {
      setLogo(
        <div className="m-2 d-flex justify-content-center">
          <img src={event.SponsorLogoURL} className="EventLogo" />
        </div>
      );
    } else {
      const cachedLogo = LogoService.getLogo(event.Id);

      if (cachedLogo !== undefined) {
        if (cachedLogo !== null) {
          setLogo(
            <img
              src={`data:image/png;base64,${cachedLogo}`}
              className="EventLogo"
            />
          );
        }
      } else {
        setLogo(
          <i className="fas fa-spinner fa-spin" style={{ fontSize: "24px" }} />
        );
        EventService.getOrganisationLogo(event.Id).then((r) => {
          LogoService.setLogo(event.Id, r.data.orgLogo);
          if (r.data.orgLogo !== null) {
            setLogo(
              <img
                src={`data:image/png;base64,${r.data.orgLogo}`}
                className="EventLogo"
              />
            );
          } else {
            setLogo(<></>);
          }
        });
      }
    }
  }, [event]);

  const handleViewResults = (eventId) => (e) => {
    navigate(
      `/event-details/${eventId}/${moment(event.CalendarDate).format(
        "YYYY-MM-DD"
      )}`
    );
  };

  return (
    <div
      role="button"
      onClick={handleViewResults(event.Id)}
      className={`calendar-schedule-item pe-1 py-1 d-flex`}
    >
      <div className="col-3 col-md-1 d-flex justify-content-center">
        <div className="calendar-schedule-item-logo">{logo}</div>
      </div>
      <div className="col-8 col-md-10 calendar-schedule-item-date">
        <div className="col-6">
          <i className="fas fa-calendar-alt me-2" />
          {event.Filtered
            ? Util.formatSingleDates(event.Dates)
            : moment(event.CalendarDate).format("D-MMM").toLocaleLowerCase()}
        </div>
        <div className="col-12 pe-2 calendar-schedule-item-name">
          {event.EventName}
        </div>
        <div className="EventListItemEventClub d-flex">
          <div className="col-12 d-flex justify-content-start">
            {event.ClubVenue}
          </div>
        </div>
      </div>
      <div className="col-1 px-2 py-0 d-flex justify-content-end">
        {event.HasResults && (
          <div
            role="button"
            className="btn btn-sm btn-primary calendar-list-item-results"
          >
            <i className="fas fa-medal" />
          </div>
        )}
      </div>
    </div>
  );
};

export default CalendarScheduleItem;
