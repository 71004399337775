import React, { useEffect, useState } from "react";

const NewsFilter = ({ selectedCategory, categories, ...props }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    // if #news-category-list has show class, add event listener to close it when clicking outside

    document.addEventListener("click", (e) => {
      if (
        show &&
        //and target id not equal to news-category-list or their children
        e.target.id != "news-category-list" &&
        e.target.id != "news-filter-button"
      )
        setShow(false);
    });

    return () => {
      document.addEventListener("click", (e) => {
        if (
          show &&
          e.target.id != "news-category-list" &&
          e.target.id != "news-filter-button"
        )
          setShow(false);
      });
    };
  }, [show]);

  return (
    <div className="news-menu col-12">
      <div className="news-menu-title-container">
        <i className={`fas fa-rss fa-2x me-2 `} />{" "}
        <div className="news-menu-title"> News</div>
      </div>
      <div>
        {/* a div showing the categories list, only visible after clicking filter button */}
        <i
          role="button"
          id="news-filter-button"
          className={`fas fa-filter fa-2x me-2 `}
          onClick={() => setShow(!show)}
        />
        <div
          id="news-category-list"
          className={`news-category-list ${show ? "show" : ""}`}
        >
          {categories.map((c) => (
            <div
              key={c.CategoryId}
              className={`news-category-item  ${
                selectedCategory?.CategoryId == c.CategoryId ? "selected" : ""
              }`}
              onClick={() => props.onCategorySelected(c)}
            >
              {c.Category} <i className="ms-1 fas fa-arrow-circle-right" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewsFilter;
