import Cookie from "./Components/Common/App/cookie";
import Util from "./Components/Common/util";
import { useEffect, useState } from "react";
let installed = false;

export function useAddToHomescreenPrompt() {
  const [prompt, setState] = useState(null);
  const [isVisible, setVisible] = useState(
    Util.isIos() &&
      !Util.isInStandaloneMode() &&
      !(Cookie.get("install-banner-visible", "true") === "false")
  );
  function promptToInstall(callback) {
    if (prompt) {
      prompt.prompt();
      prompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          installed = true;
          setVisible(false);
        }
      });
      return Promise.resolve();
    }
    return Promise.reject(
      new Error(
        'Tried installing before browser sent "beforeinstallprompt" event'
      )
    );
  }

  function ready(e) {
    e.preventDefault();
    if (!installed) {
      installed =
        (Util.isIos() && Util.isInStandaloneMode()) ||
        (!Util.isIos() && Util.isAndroidStandalone());
    }

    if (!installed) {
      setState(e);
      setVisible(true);
    } else {
      hide();
    }
  }
  function hide() {
    setVisible(false);
  }

  useEffect(() => {
    self.addEventListener("beforeinstallprompt", ready);
    self.addEventListener("appinstalled", hide);
    return () => {
      self.removeEventListener("beforeinstallprompt", ready);
      self.removeEventListener("appinstalled", hide);
    };
  }, []);
  return [isVisible, setVisible, promptToInstall];
}
