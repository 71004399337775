import { get } from "./http-instance";
export default class NewsService {
  static async get(filter) {
    return get("App/News", { PageNumber: filter.PageNumber });
  }

  static async getById(contentId) {
    return get("App/GetNewsById", { contentId });
  }

  static async getCategories() {
    return get("App/GetNewsCategories");
  }
}
