import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

const RadioGroup = ({
  label,
  value,
  required = true,
  children,
  onChange,
  readOnly = false,
}) => {
  const [selectedValue, setSelectedValue] = useState(value);

  const handleRadioChange = (value) => {
    setSelectedValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const isValid = () => {
    return required ? selectedValue !== null : true;
  };
  return (
    <Form.Group>
      <Form.Label className="fw-bold">{label}</Form.Label>
      <div className="px-3 my-1">
        {React.Children.map(children, (child) => {
          return React.cloneElement(child, {
            onChange: () => handleRadioChange(child.props.value),
            checked: selectedValue == child.props.value,
            disabled: readOnly,
          });
        })}
      </div>
    </Form.Group>
  );
};

export default RadioGroup;
