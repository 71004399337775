import React, { useEffect, useState } from "react";
import EventService from "../../../Services/event-service";
import HandicappedTable from "./event.details.series.list.item.seriesresults.list.expandedInfo.handicapped";
import OneDesignTable from "./event.details.series.list.item.seriesresults.list.expandedInfo.onedesign";

const SeriesResultsListExpandedInfo = ({
  series,
  seriesId,
  seriesResult,
  raceType,
}) => {
  const [results, setResults] = useState();

  useEffect(() => {
    //etCompetitorResults(seriesResult);
    //reduce from series?.Races to get all the raceResults where CompetitorId == seriesResult.CompetitorId

    var results = [...series?.Races]?.reduce((results, race) => {
      const competitorResult = race?.raceResults?.find(
        (result) => result.CompetitorId === seriesResult.CompetitorId
      );
      if (competitorResult) {
        results.push(competitorResult);
      }
      return results;
    }, []);
    //console.log(results);
    setResults(results);
  }, [JSON.stringify(seriesResult), JSON.stringify(series)]);

  //UNUSED
  // const getCompetitorResults = (seriesResult) => {
  //   EventService.getCompetitorResults(seriesResult.CompetitorId, seriesId)
  //     .then((data) => {
  //       setResults(data.results);
  //       //console.log(data.results);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       // Handle any errors here
  //     });
  // };

  if (raceType == "OneDesign") {
    return <OneDesignTable seriesResult={seriesResult} results={results} />;
  } else if (raceType == "Handicap") {
    return <HandicappedTable seriesResult={seriesResult} results={results} />;
  } else {
    return <></>;
  }
};

export default SeriesResultsListExpandedInfo;
