import React, { useState, useEffect } from "react";
const Navbar = React.lazy(() => import("react-bootstrap/Navbar"));
import { Link } from "react-router-dom";
import logo from "../../Assets/img/Sailing_LIVE_Logo121x113.png";
import logoTEST from "../../Assets/img/Sailing_LIVE_Logo121x113TEST.png";

const LoginMenu = ({
  pathname,
  onBackButton,
  showBackButon = false,
  ...props
}) => {
  const [backButton, setBackButton] = useState(false);
  const [flipMenuIcon, setFlipMenuIcon] = useState(false);

  useEffect(() => {
    setFlipMenuIcon(!flipMenuIcon);
    setBackButton(hasBackButton());

    return () => {};
  }, []);

  const hasBackButton = () => {
    let hasPreviousPage = window?.history?.length > 1;
    return hasPreviousPage ? showBackButon : false;
  };

  const loadHomePage = () => {
    cleanStorage();
    updateSw();
  };

  const updateSw = () => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.getRegistration().then((reg) => {
        if (!reg) {
          return null;
        }

        reg.update();
      });
    }
  };

  const goBackHandler = () => {
    if (onBackButton) {
      onBackButton();
      return;
    }

    //get -1 history path
    window?.history?.length > 1 ? window.history.go(-1) : null;
  };

  const cleanStorage = () => {
    var sailingLiveItems = Object.keys(localStorage).filter((key) =>
      key.startsWith("SAILINGLIVE_")
    );
    sailingLiveItems?.forEach((key) => {
      localStorage.removeItem(key);
    });
  };

  return (
    <>
      <Navbar
        bg="white"
        expand=""
        fixed="top"
        className="login-header-wrap d-flex justify-content-around  align-items-start position-relative col-12"
        id="top-navbar"
      >
        <div className="col-4 d-flex justify-content-start">
          {backButton && (
            <a
              className="navbar-toggler back-btn"
              onClick={goBackHandler}
              id="main-back-button"
            >
              <i className="fa fa-arrow-left"></i>
            </a>
          )}
        </div>
        <div className="col-4 d-flex justify-content-center">
          <Link to="/" className="login-logo-wrap" onClick={loadHomePage}>
            <img
              src={!process.env.REACT_APP_TEST ? logo : logoTEST}
              height="auto"
              className="align-center"
              alt="Sailing Live"
            />
          </Link>
        </div>
        <div className="col-4 d-flex justify-content-end align-items-center"></div>
      </Navbar>
    </>
  );
};

export default LoginMenu;
