import React from "react";
import { useAddToHomescreenPrompt } from "../../../sw.js";
//import { Button, Row, Col } from "react-bootstrap";
const { Button, Row, Col } = require("react-bootstrap");
import Util from "../util.jsx";
import Cookie from "./cookie.jsx";

function InstallBanner() {
  const [isVisible, setVisible, promptToInstall] = useAddToHomescreenPrompt();
  const hide = () => {
    setVisible(false);
    Cookie.set("install-banner-visible", false, 1);
  };
  isVisible
    ? document.body.classList.add("top-banner")
    : document.body.classList.remove("top-banner");
  const cookieVisible = Cookie.get("install-banner-visible", "true");
  if (cookieVisible === "false") return "";

  if (!isVisible) return "";
  let banner;
  if (Util.isIos()) {
    banner = (
      <Row className={isVisible ? "install-banner-ios" : "hide"}>
        <Col xs={2} className="install-banner-left floatLeft displayFlexCenter">
          <span onClick={hide} className="install-banner-left-closebtn">
            {" "}
            <i className="fa fa-times fa-2x" />{" "}
          </span>
        </Col>
        <Col xs={10} className="install-banner-center floatLeft">
          <h5 className="banner-heading"> {process.env.REACT_APP_NAME} </h5>
          <p className="banner-sub-heading">
            To add Sailing Live to your home screen click on the{" "}
            <img src="assets/img/ios-install-button.png" height="24" /> icon and
            select{" "}
            <span className="ios-blue">
              Add to home Screen <i className="far fa-plus-square" />
            </span>
            . Once installed you can close this message.
          </p>
        </Col>
      </Row>
    );
  } else {
    banner = (
      <Row className={isVisible ? "install-banner" : "hide"}>
        <Col xs={1} className="install-banner-left floatLeft displayFlexCenter">
          <span onClick={hide} className="install-banner-left-closebtn">
            {" "}
            <i className="fa fa-times fa-2x" />{" "}
          </span>
        </Col>
        <Col xs={8} className="install-banner-center floatLeft">
          <h5 className="banner-heading"> {process.env.REACT_APP_NAME} </h5>
          <p className="banner-sub-heading">
            Get our app to view live results for Sailing in Ireland.{" "}
          </p>
        </Col>
        <Col
          xs={3}
          className="install-banner-right floatRight displayFlexCenter"
        >
          <Button
            variant="success"
            className="banner-install-btn"
            onClick={promptToInstall}
          >
            Install
          </Button>
        </Col>
      </Row>
    );
  }

  return (
    <React.Fragment>
      <nav>
        <div className="banner-wrapper"></div>
        {banner}
      </nav>
    </React.Fragment>
  );
}
export default InstallBanner;
