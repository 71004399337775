import React, { useState, useEffect } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import FormTextInput from "../../../../Components/Common/Inputs/text.form.input";
import FormSelectInput from "../../../../Components/Common/Inputs/select.form.input";
import FormTextAreaInput from "../../../../Components/Common/Inputs/textarea.form.input";
import RadioGroup from "../../../../Components/Common/Inputs/radio.group.form.input";
import MyEventsDatesSelector from "./my.events.dates.selector";
import MyEventsRaceCommittee from "./my.events.race.committee";
import { toast } from "react-toastify";
import Util from "../../../../Components/Common/util";
import { useParams } from "react-router-dom";
import { useUser } from "../../../../Components/Common/context";
import ManageEventService from "../../../../Services/manage-event-service";
import moment from "moment";

//Race Committee example:
const defaultRCModel = [
  {
    Role: "Race Officer", // or Safety Leader, Mark Layer, Jury/Protest Committee, Umpire (team racing only), Results Manager, Measurer/Equipment Inspector.
    Requirement: "Not sure / Not needed", // or "Have one" or "Need one"
  },
  {
    Role: "Safety Leader",
    Requirement: "Not sure / Not needed",
  },
  {
    Role: "Mark Layer",
    Requirement: "Not sure / Not needed",
  },
  {
    Role: "Jury/Protest Committee",
    Requirement: "Not sure / Not needed",
  },
  {
    Role: "Umpire (team racing, match racing or umpired fleet racing only)",
    Requirement: "Not sure / Not needed",
  },
  {
    Role: "Results Manager",
    Requirement: "Not sure / Not needed",
  },
  {
    Role: "Measurer/Equipment Inspector",
    Requirement: "Not sure / Not needed",
  },
];
const MyEventsDetailForm = ({ onSave, onDelete }) => {
  const { user } = useUser();
  const defValues = {
    clubVenue: user.OrganisationName,
    address: user.Address,
    town: user.Town,
    county: user.County,
    eircode: user.EIRCode,
  };
  const {
    register,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    //defaultValues = defValues,
    formState: { errors },
  } = useForm();
  const { eventId } = useParams();
  const [event, setEvent] = useState();
  const [isSubmit, setIsSubmit] = useState(false);
  const [isApprove, setIsApprove] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // so: false if new, true if edit.
  const [dates, setDates] = useState([]);
  const [openTo, setOpenTo] = useState();
  const [eventType, setEventType] = useState();
  //make deep copy of defaultRCModel
  const model = JSON.parse(JSON.stringify(defaultRCModel));
  const [raceCommittee, setRaceCommitee] = useState(model);
  const [counties, setCounties] = useState(null);
  const [organisations, setOrganisations] = useState(null);

  useEffect(() => {
    setRaceCommitee(model);
    raceCommittee.forEach((rc, i) => {});
    getCounties();
    getValidOrganisations();
    return () => {
      reset(event);
      setRaceCommitee(model);
    };
  }, []);

  useEffect(() => {
    if (!event || !user) return;
    var _event = { ...event };

    reset(event);
    setDates(_event.dates.map((d) => d));
    setEventType(_event.eventType);
    setOpenTo(_event.openTo);
    setRaceCommitee(_event.raceCommittee);

    let isAdminUser = user?.UserID && !user?.OrganisationId;
    setIsReadOnly(
      !isAdminUser && (_event.submittedDate || _event.approvedDate)
    );
  }, [event, user, counties]);

  useEffect(() => {
    if (!eventId) return;
    getEventDetails();
  }, [eventId]);

  useEffect(() => {
    if (counties?.length < 2) return;
    if (!eventId) {
      //reset(defaultValues);
    }
  }, [counties]);

  const getCounties = () => {
    ManageEventService.listCounties()
      .then((r) => {
        var _counties = r.data.map((c) => {
          var _county = {
            label: c.county,
            value: c.county,
          };
          return _county;
        });
        _counties.unshift({ label: "", value: null });
        setCounties(_counties);
      })
      .catch((e) => toast.error(e));
  };

  const getValidOrganisations = () => {
    ManageEventService.listValidOrganisations()
      .then((r) => {
        if (!r?.data?.validOrganisations)
          throw new Error("No valid organisations found");

        var _organisations = r.data.validOrganisations;
        setOrganisations(_organisations);
      })
      .catch((e) => toast.error(e));
  };

  const getEventDetails = () => {
    setIsLoading(true);
    ManageEventService.getEventDetails({ eventId })
      .then((r) => {
        setEvent(r.data);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        toast.error(e);
      });
  };

  const ButtonsLayout = () => {
    if (!user) return;

    let isSubmitted = event?.submittedDate;
    let isApproved = event?.approvedDate;
    let isAdminUser = user?.UserID && !user?.OrganisationId;
    let isAdminEvent = isAdminUser && user?.UserID === event?.crUser;
    let saveButtons = [];
    let approveButton = null;
    let deleteButton = null;
    const pushTheContinueButton = () =>
      saveButtons.push(
        <Button
          type="submit"
          key="saveContinue"
          className="col-5 btn btn-sm btn-dark"
        >
          Save and Continue
        </Button>
      );
    //conditions

    // if (isApproved) {
    //   return;
    // }

    const SaveRow = ({ children }) => {
      return (
        <div className="col-12 d-flex justify-content-between mb-2">
          {children.map((c) => c)}
        </div>
      );
    };

    const ApproveRow = ({ children }) => {
      if (isApproved) return <></>;
      return (
        <div className="col-12 d-flex justify-content-center mb-2 ">
          {children}
        </div>
      );
    };

    const DeleteRow = () => {
      if (!event?.id) return <></>;
      if (!isAdminUser && isSubmitted) return <></>;
      if (!isAdminUser && isApproved) return <></>;

      return (
        <div className="col-12 d-flex justify-content-center">
          <div
            role="button"
            onClick={handleDelete}
            className="col-5 btn btn-sm btn-danger"
          >
            Delete
          </div>
        </div>
      );
    };

    if (isSubmitted) {
      if (isAdminUser) {
        pushTheContinueButton();
      }
    } else {
      pushTheContinueButton();
    }

    if (!isSubmitted) {
      saveButtons.push(
        <Button
          type="submit"
          name="saveSubmit"
          key="saveSubmit"
          className="col-5 btn btn-sm btn-dark"
        >
          Save and Submit
        </Button>
      );
    }

    //ADMIN BUTTONS
    if (isAdminUser) {
      if (isSubmitted) {
        approveButton = (
          <Button
            type="submit"
            name="approve"
            className="col-5 btn btn-sm btn-dark"
          >
            Approve
          </Button>
        );
      }
    }

    return (
      <>
        <SaveRow>{saveButtons}</SaveRow>
        <ApproveRow>{approveButton}</ApproveRow>
        <DeleteRow />
      </>
    );
  };

  const OrganisationsList = () => {
    //if (!user.PersonId) return <></>;
    return (
      <>
        <FormSelectInput
          name="organisationId"
          label={"Organisation"}
          register={register}
          registerOptions={{
            required: true,
            validate: (value) => value !== null,
          }}
          errors={errors}
          className="form-control"
          groupClassName="col-12 mb-2"
          labelClasses={"fw-bold"}
          options={
            organisations
              ? organisations.map((o) => {
                  return {
                    label: o.OrganisationName,
                    value: o.Id,
                  };
                })
              : []
          }
          readOnly={isReadOnly}
        />
      </>
    );
  };

  const EventMetaData = () => {
    if (!event?.id) return <></>;
    //a legend showing crname and crdate
    return (
      <div className="col-12 d-flex justify-content-end mt-3">
        <div className="col-12 d-flex justify-content-end fw-bold">
          <p>
            Created by {event.crName} on{" "}
            {moment(event.crDate).format("D-MMM-YYYY")}
          </p>
        </div>
      </div>
    );
  };

  const handleDelete = () => {
    onDelete(event);
  };

  const onSubmit = (formData, e) => {
    var isSubmit = e.nativeEvent.submitter.name === "saveSubmit";
    var isApprove = e.nativeEvent.submitter.name === "approve";
    var noDates = dates.length === 0;
    var noOpenTo = Util.undefined(openTo);
    var noEventType = Util.undefined(eventType);
    let validationErrors = null;
    if (noDates) {
      validationErrors = [...(validationErrors || []), "At least one date"];
      //toast.error("At least one date is required");
    }
    if (noEventType) {
      validationErrors = [...(validationErrors || []), "Event type"];
      //toast.error("Event type is required");
    }
    if (noOpenTo) {
      validationErrors = [...(validationErrors || []), "Open to"];
      //toast.error("Open to is required");
    }
    if (validationErrors) {
      const errorMessage = `The following fields are required: ${validationErrors.join(
        ", "
      )}`;
      toast.error(errorMessage);
      return;
    }

    //if (noDates || noOpenTo || noEventType) return;

    var DNNUserId = parseInt(user.UserID);

    var data = {
      ...formData,
      eventType,
      dates,
      openTo,
      raceCommittee,
      DNNUserId, //used to set LMUser on backend
      submittedDate: isSubmit
        ? moment().toISOString(true)
        : event?.submittedDate,
      submittedByUserId: isSubmit ? DNNUserId : event?.submittedByUserId,
      approvedDate: isApprove
        ? moment().toISOString(true)
        : event?.approvedDate,
      approvedByUserId: isApprove ? DNNUserId : event?.approvedByUserId,
    };
    if (!event?.id) {
      data.crName = user.DisplayName;
    }
    data.lmName = user.DisplayName;
    data.env = process.env.REACT_APP_APP_ENV;
    data.isAdminUser = user?.UserID && !user?.OrganisationList && user.IsAdmin;

    onSave(data);
  };

  return (
    <>
      <div className="p-3">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <InputGroup>
            <OrganisationsList />
            <FormTextInput
              name="eventName"
              label={"Event Name"}
              type="text"
              register={register}
              registerOptions={{ required: true }}
              errors={errors}
              className="form-control"
              groupClassName="col-12 mb-1"
              labelClasses={"fw-bold"}
              readOnly={isReadOnly}
            />
            <FormTextInput
              name="clubVenue"
              label={"Name of the host Club or Venue"}
              type="text"
              register={register}
              registerOptions={{ required: true }}
              errors={errors}
              className="form-control"
              groupClassName="col-12 mb-2"
              labelClasses={"fw-bold"}
              readOnly={isReadOnly}
            />
            <FormTextInput
              name="address"
              label={"Address"}
              type="text"
              register={register}
              registerOptions={{ required: true }}
              errors={errors}
              className="form-control"
              groupClassName="col-12 mb-2"
              labelClasses={"fw-bold"}
              readOnly={isReadOnly}
            />
            <FormTextInput
              name="town"
              label={"Town"}
              type="text"
              register={register}
              registerOptions={{ required: false }}
              errors={errors}
              className="form-control"
              groupClassName="col-12 mb-2"
              labelClasses={"fw-bold"}
              readOnly={isReadOnly}
            />
            <FormSelectInput
              name="county"
              label={"County"}
              register={register}
              registerOptions={{
                required: true,
                validate: (value) => value !== null,
                defaultValue: defValues.county,
              }}
              errors={errors}
              className="form-control"
              groupClassName="col-12 mb-2"
              labelClasses={"fw-bold"}
              options={counties}
              readOnly={isReadOnly}
            />
            <FormTextInput
              name="eircode"
              label={"Eircode"}
              type="text"
              register={register}
              registerOptions={{ required: false }}
              errors={errors}
              className="form-control"
              groupClassName="col-12 mb-2"
              labelClasses={"fw-bold"}
              readOnly={isReadOnly}
            />
            <RadioGroup
              label="Event Type"
              name="eventType"
              value={eventType}
              onChange={(value) => setEventType(value)}
              readOnly={isReadOnly}
            >
              <Form.Check
                type="radio"
                label="Multi Class"
                name="eventType"
                value="Multi Class"
                id="multiClass"
              />
              <Form.Check
                type="radio"
                label="Single Class"
                name="eventType"
                value="Single Class"
                id="singleClass"
              />
              <Form.Check
                type="radio"
                label="Cruising Event"
                name="eventType"
                value="Cruising Event"
                id="cruisingEvent"
              />
              <Form.Check
                type="radio"
                label="Team Racing"
                name="eventType"
                value="Team Racing"
                id="teamRacing"
              />
              <Form.Check
                type="radio"
                label="Match Racing"
                name="eventType"
                value="Match Racing"
                id="teamRacing"
              />
              <Form.Check
                type="radio"
                label="Conference or Talk"
                name="eventType"
                value="Conference or Talk"
                id="conference"
              />
              <Form.Check
                type="radio"
                label="Other"
                name="eventType"
                value="Other"
                id="other"
              />
            </RadioGroup>
            <FormTextAreaInput
              name="classes"
              label={"Classes"}
              placeholder={""}
              register={register}
              registerOptions={{ required: false, maxLength: 250 }}
              errors={errors}
              className="form-control"
              groupClassName="col-12 mb-2"
              labelClasses={"fw-bold"}
              maxLength={250}
              readOnly={isReadOnly}
            />
            <RadioGroup
              label="Open to"
              name="openTo"
              value={openTo}
              onChange={(value) => setOpenTo(value)}
              readOnly={isReadOnly}
            >
              <Form.Check
                type="radio"
                name="openTo"
                label="Open to all"
                value="All"
                id="all"
              />
              <Form.Check
                type="radio"
                label="Club Only"
                value="Club Only"
                name="openTo"
                id="clubOnly"
              />
            </RadioGroup>

            <FormTextInput
              name="organiserEmail"
              label={"Organiser Email"}
              type="text"
              register={register}
              registerOptions={{ required: true }}
              errors={errors}
              className="form-control"
              groupClassName="col-12 mb-2"
              labelClasses={"fw-bold"}
              readOnly={isReadOnly}
            />
            <FormTextInput
              name="eventWebsite"
              label={"Organiser Website"}
              type="text"
              register={register}
              registerOptions={{ required: true }}
              errors={errors}
              className="form-control"
              groupClassName="col-12 mb-2"
              labelClasses={"fw-bold"}
              readOnly={isReadOnly}
            />
            <FormTextInput
              name="principalRaceOfficer"
              label={
                "Principal Race Officer Name (For Cruising events, please note name of event organiser)"
              }
              type="text"
              register={register}
              registerOptions={{ required: true }}
              errors={errors}
              className="form-control"
              groupClassName="col-12 mb-2"
              labelClasses={"fw-bold"}
              readOnly={isReadOnly}
            />
            <MyEventsDatesSelector
              dates={dates}
              setDates={setDates}
              readOnly={isReadOnly}
            />

            <MyEventsRaceCommittee
              raceCommittee={raceCommittee}
              setRaceCommitee={setRaceCommitee}
              readOnly={isReadOnly}
            />

            <FormTextAreaInput
              name="notes"
              label={"Notes"}
              placeholder={""}
              register={register}
              registerOptions={{ required: false, maxLength: 250 }}
              errors={errors}
              className="form-control"
              groupClassName="col-12 mb-2"
              labelClasses={"fw-bold"}
              maxLength={250}
              readOnly={isReadOnly}
            />
          </InputGroup>
          <EventMetaData />
          <ButtonsLayout />
        </Form>
      </div>
    </>
  );
};

export default MyEventsDetailForm;
