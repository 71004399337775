import React, { useState } from "react";
import EventList from "../Events/Components/events.list";
import FilterTabs from "./Components/filter.tabs";
const MainMenu = React.lazy(() =>
  import("../../Components/Main-Menu/menu.main")
);

const Search = () => {
  const [events, setEvents] = useState([]);

  return (
    <>
      <MainMenu showBackButon={false} />
      <div className="main-panel">
        <FilterTabs onResults={(results) => setEvents(results)} />

        <EventList events={events} isSearch selectedEventDate={null} />
      </div>
    </>
  );
};

export default Search;
