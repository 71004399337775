export default class NotificationEvent {
    #subscribers;
    constructor() {
        this.#subscribers = [];
    }

    dispatch(payload) {
        this.#subscribers.forEach(s => s(payload))
    }

    subscribe(listener) {
        if(!this.#subscribers.includes(listener)){
            this.#subscribers.push(listener);
        }
    }
    unsubscribe(listener) {
        const index = this.#subscribers.indexOf(listener)
        if (index >= 0){
            this.#subscribers.splice(index, 1);
        }
    }
}