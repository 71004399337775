import axios from "axios";

import { httpClient } from "./http-instance";
import Util from "../Components/Common/util";

/* Login Instance */
const loginClient = axios.create({
  baseURL: process.env.REACT_APP_LOGIN_API_ENDPOINT,
  headers: {
    "Cache-Control": "no-cache",
    "Content-type": "application/json; charset=utf-8 ",
  },
});

const login = (user, password, onSuccess, onError) => {
  loginClient
    //.post("Login/LoginUser", { u: user, p: password })
    .post("mobile/login", { u: user, p: password })
    .then(({ data }) => {
      if (Util.undefined(data.accessToken)) {
        throw new Error("Invalid user.");
      }
      let roleData = parseJwt(data.accessToken);
      saveToLocalStorage(data);
      onSuccess && onSuccess();
    })
    .catch(({ message, request }) => {
      var errMsg =
        request?.status === 401 ? "Invalid username or password" : message;
      onError && onError(errMsg);
    });
};

const refreshToken = (retryRequest) => {
  const refreshToken = localStorage.getItem("rtoken");
  const token = localStorage.getItem("token");
  if (isDefined(token) && isDefined(refreshToken)) {
    return loginClient
      .post(
        "mobile/extendtoken",
        { rtoken: refreshToken },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then(({ data }) => {
        // console.log(
        //   "previousRequest to be called after refresh token",
        //   retryRequest
        // );
        saveToLocalStorage(data);
        //Here add manually the Authorization header
        retryRequest.headers["Authorization"] = "Bearer " + data?.accessToken;
        return httpClient(retryRequest);
      })
      .catch((error) => {
        console.log("Error when refreshing token");
        logOut();
      });
  }
  logOut();
};

const logOut = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("rtoken");
  window.location.href = "/home";
};
/*End Login Instance */

export { login, logOut, refreshToken, isTokenExpired };

/* Private methods */
const saveToLocalStorage = (dnnTokenResponse) => {
  const { accessToken, renewalToken } = dnnTokenResponse;
  localStorage.setItem("token", accessToken);
  localStorage.setItem("rtoken", renewalToken);
};

function isTokenExpired() {
  const token = localStorage.getItem("token");
  if (token) {
    const { exp } = parseJwt(token);
    const currentTime = new Date().getTime() / 1000;
    return exp < currentTime;
  } else return true;
}

const parseJwt = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  return decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
      .join("")
  );
};

const undefined = (obj) =>
  obj === undefined ||
  obj === null ||
  obj === "" ||
  obj === "NULL" ||
  typeof obj === "undefined";
const isDefined = (obj) => !undefined(obj);

//*End - Private methods */
