import React, { useState, useEffect, useMemo } from "react";
import MainMenu from "../../Components/Main-Menu/menu.main";
import { toast } from "react-toastify";
import NewsService from "../../Services/news-service";
import NewsList from "./Components/news.list";
import NewsFilter from "./Components/news.filter";

const NewsScreen = ({ ...props }) => {
  const all = { CategoryId: "All", Category: "All" };
  const [page, setPage] = useState(
    JSON.parse(localStorage.getItem("SAILINGLIVE_newsCache"))?.lastPage || 0
  );
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [newsCache, setNewsCache] = useState(
    JSON.parse(localStorage.getItem("SAILINGLIVE_newsCache")) || null
  );

  const [categoriesCache, setCategoriesCache] = useState(
    JSON.parse(localStorage.getItem("SAILINGLIVE_newsCategoriesCache")) || null
  );
  const [categories, setCategories] = useState(
    categoriesCache?.Categories || [all]
  );
  const [selectedCategory, setSelectedCategory] = useState(
    JSON.parse(localStorage.getItem("SAILINGLIVE_selectedCategory")) || all
  );

  useEffect(() => {
    //a day in milliseconds = 1000*60*60*24
    if (
      !categoriesCache ||
      new Date(categoriesCache.lastUpdateTime) <
        new Date().getTime() - 1000 * 60 * 60 * 24
    ) {
      NewsService.getCategories()
        .then((r) => {
          setCategories([all, ...r.data.data]);
          localStorage.setItem(
            "SAILINGLIVE_newsCategoriesCache",
            JSON.stringify({
              Categories: [all, ...r.data.data],
              lastUpdateTime: new Date(),
            })
          );
        })
        .catch((error) => {
          console.error(error);
          // Handle any errors here
        });
    }
    //if no newsCache, or newsCache.lastUpdateTime > 1hour ago...
    if (
      !newsCache ||
      new Date(newsCache.lastUpdateTime) < new Date().getTime() - 3600000
    ) {
      getNews();
      return;
    }
    setData({
      News: newsCache.News,
      TotalRows: newsCache.TotalRows,
      lastPage: newsCache.lastPage,
      lastUpdateTime: newsCache.lastUpdateTime,
    });
    setPage(newsCache.lastPage);
  }, []);

  const getNews = (newPage = page + 1) => {
    if (page == newPage) return;
    setIsLoading(true);
    setPage(newPage);
    NewsService.get({ PageNumber: newPage })
      .then((r) => {
        const rData = r.data.data;
        setData({
          News: rData.News,
          TotalPages: rData.TotalPages,
          TotalRows: rData.TotalRows,
        });

        let news = JSON.parse(
          localStorage.getItem("SAILINGLIVE_newsCache")
        )?.News;
        if (!news) {
          localStorage.setItem(
            "SAILINGLIVE_newsCache",
            JSON.stringify({
              News: rData.News,
              lastPage: newPage,
              TotalRows: rData.TotalRows,
              lastUpdateTime: rData.News.length > 0 ? new Date() : null,
            })
          );
        } else {
          localStorage.setItem(
            "SAILINGLIVE_newsCache",
            JSON.stringify({
              News: [...news, ...rData.News],
              lastPage: newPage,
              TotalRows: rData.TotalRows,
              lastUpdateTime: rData.News.length > 0 ? new Date() : null,
            })
          );
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        toast.error("Error getting news");
        setIsLoading(false);
      });
  };

  const handleSelectCategory = (category) => {
    setSelectedCategory(category);
    localStorage.setItem(
      "SAILINGLIVE_selectedCategory",
      JSON.stringify(category)
    );
  };

  return (
    <>
      <MainMenu showBackButon={false} />
      <div className="main-panel">
        <NewsFilter
          categories={categories}
          selectedCategory={selectedCategory}
          onCategorySelected={(c) => handleSelectCategory(c)}
        />
        <div className="news-wrapper col-12">
          <NewsList
            data={data}
            page={page}
            filter={selectedCategory}
            isLoading={isLoading}
            onLoadMore={(page) => getNews(page)}
          />
        </div>
      </div>
    </>
  );
};

export default NewsScreen;
