import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const EventResultsFleetItem = ({ eventId, fleet, date, ...props }) => {
  const navigate = useNavigate();
  return (
    <>
      <div
        className="col-12 EventResultsFleetItem"
        onClick={() =>
          navigate(`/event-results/${eventId}/${date}/${fleet.Id}`)
        }
      >
        <div className="EventResultsFleetItemName">{fleet.FleetName}</div>
        <div role="button" className="EventResultsFleetItemArrow">
          <div className="circleArrow">
            <i className="fas fa-arrow-right" />
          </div>
        </div>
      </div>
    </>
  );
};

export default EventResultsFleetItem;
