import React, { useEffect, useLayoutEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import EventService from "../../Services/event-service";
import MainMenu from "../../Components/Main-Menu/menu.main";
import EventResultsHeader from "./Components/event.results.header";
import { toast } from "react-toastify";
import SeriesDetails from "../EventDetails/Components/event.series.detail";

const EventResultsScreen = ({ onBack }) => {
  const [event, setEvent] = useState();
  const [series, setSeries] = useState();
  const [fleets, setFleets] = useState([]);
  const { eventId, date, fleetId, seriesId } = useParams();
  const [cameFromSearch, setCameFromSearch] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useLayoutEffect(() => {
    if (!(eventId && fleetId && date)) return;
    getEventResults();
  }, [fleetId, eventId, date]);

  useEffect(() => {
    if (!seriesId || !series) return;
    setTimeout(() => {
      var firstSeriesId = series[0].id;
      if (seriesId == firstSeriesId) return;

      var serieDiv = document.querySelector(`#series-${seriesId}`);
      serieDiv?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "center",
      });
    }, 10);
  }, [seriesId]);

  useEffect(() => {
    if (!location.state) return;
    setCameFromSearch(location.state?.isSearch);
  }, [JSON.stringify(location.state)]);

  const getEventResults = () => {
    EventService.getEventDetailsAndSeries(eventId, date, fleetId)
      .then((r) => {
        setEvent(r.data.eventData);
        setSeries(r.data.series);
        setFleets(r.data.eventData?.Fleets);
      })
      .catch((e) => {
        console.error(e);
        toast.error("Error getting event results");
      });
  };

  const onBackButton = () => {
    if (cameFromSearch) {
      navigate("/search");
      return;
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <MainMenu showBackButon={true} onBackButton={onBackButton} />
      <div className="main-panel">
        <EventResultsHeader
          event={event}
          fleetId={fleetId}
          series={series}
          date={date}
        />
        {series?.map((serie, index) => {
          return (
            <React.Fragment key={index}>
              <SeriesDetails series={serie} event={event} fleetId={fleetId} />
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
};

export default EventResultsScreen;
