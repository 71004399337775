import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

const RaceList = ({
  eventId,
  seriesId,
  races,
  isSearch,
  onOverallClick,
  onWideClick,
  onRaceClick,
  selectedRaceId,
  selectedMode,
}) => {
  //is wide in the url?
  const _raceId = useParams().raceId;
  const [isWide, setIsWide] = useState(window.location.href.includes("wide"));
  const [raceId, setRaceId] = useState(selectedRaceId || _raceId);
  const { fleetId, eventDate } = useParams();
  useEffect(() => {
    window.addEventListener("resize", () => handleRaceButtonsList());
    return () => {
      window.removeEventListener("resize", handleRaceButtonsList());
    };
  });
  useEffect(() => {
    handleRaceButtonsList();
    return;
    //check if wide is in the url
    setIsWide(window.location.href.includes("wide"));
  }, []);

  useEffect(() => {
    if (!raceId) return;
    if (!races) return;
    scrollToCenter();
  }, [raceId, JSON.stringify(races)]);

  const scrollToCenter = () => {
    var active = document.querySelector(".btn-active");
    active &&
      active.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
  };

  const handleRaceButtonsList = () => {
    var screenWidth = window.innerWidth;
    // vas isWide is true if we are on a desktop or tablet
    var isWide = screenWidth > 768;
    setIsWide(isWide);
    var prev = document.querySelector(`#raceButtonsPrevious${seriesId}`);
    var next = document.querySelector(`#raceButtonsNext${seriesId}`);
    var container = document.querySelector(`#raceButtonsContainer${seriesId}`);
    if (!container) return;

    var containerWidth = container.offsetWidth;
    var contentWidth = 0;

    container.childNodes.forEach((child) => {
      contentWidth += child.offsetWidth;
    });

    if (parseInt(contentWidth) > parseInt(containerWidth)) {
      prev.classList.remove("d-none");
      next.classList.remove("d-none");
    } else {
      prev.classList.add("d-none");
      next.classList.add("d-none");
    }
  };

  const handleClickPrevious = () => {
    scrollContainerBy(-100, 0);
  };

  const handleClickNext = () => {
    scrollContainerBy(100, 0);
  };

  const scrollContainerBy = (x, y) => {
    var container = document.querySelector(`#raceButtonsContainer${seriesId}`);
    container.scrollBy({
      top: y,
      left: x,
      behavior: "smooth",
    });
  };

  const getWideLink = () => {
    return `/event/${eventId}/${seriesId}/wide/${fleetId}`;
  };
  const getReturnLink = () => {
    if (!fleetId) return `/event/${eventId}/${seriesId}`;

    // return `/event-date/${eventId}/${eventDate}/${seriesId}`;
    return `/event/${fleetId}${seriesId ? `/${seriesId}` : ""}`;
  };
  const getRaceLink = (raceId) => {
    if (!eventDate) return `/event/${eventId}/${seriesId}/${raceId}/${fleetId}`;
  };

  return (
    <>
      <div className="RaceList col-12 d-flex justify-content-between p-2 ps-3 pe-2">
        <div className="d-flex align-items-center fw-bold fs-6 col-2">
          Races
        </div>
        <div className="col-10 d-flex justify-content-end align-items-center">
          <div
            id={`raceButtonsPrevious${seriesId}`}
            role={"button"}
            onClick={handleClickPrevious}
            className="EventDetailsSeriesListItemRaceButton my-2 btn btn-sm p-2 border-dark rounded-0 btn-control d-flex align-items-center"
          >
            <i className="fas fa-angle-left fs-1" />
          </div>
          <div
            id={`raceButtonsContainer${seriesId}`}
            className="raceButtonsContainer d-flex justify-content-start my-2"
          >
            <div
              onClick={() => onOverallClick()}
              className={`EventDetailsSeriesListItemOVButton btn btn-sm p-2 px-3 border-dark rounded-0 ${
                selectedMode == "Overall" ? "btn-active" : ""
              } `}
              role={"button"}
            >
              Overall
            </div>
            <div
              className={`EventDetailsSeriesListItemWideButton btn btn-sm p-2 px-3 border-dark rounded-0 ${
                selectedMode == "WideView" ? "btn-active" : ""
              } `}
              onClick={() => onWideClick()}
              role={"button"}
            >
              Wide view
            </div>
            {races?.map((race, i) => {
              var active = "";
              var sailed = race.resultsCount > 0 ? "sailed" : "";
              if (race.Id == selectedRaceId && selectedMode == "RaceDetails") {
                active = "btn-active";
              }

              return (
                <div
                  className={`EventDetailsSeriesListItemRaceButton btn btn-sm p-2 px-3 border-dark rounded-0  ${sailed} ${active}`}
                  onClick={() => onRaceClick(race.Id)}
                  role={"button"}
                  key={i}
                >
                  {i + 1}
                </div>
              );
            })}
          </div>
          <div
            id={`raceButtonsNext${seriesId}`}
            role={"button"}
            onClick={handleClickNext}
            className="EventDetailsSeriesListItemRaceButton my-2 btn btn-sm p-2 border-dark rounded-0 btn-control d-flex align-items-center d-none"
          >
            <i className="fas fa-angle-right fs-1" />
          </div>
        </div>
      </div>
    </>
  );
};

export default RaceList;
