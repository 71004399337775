import { get, post, getFromWeb } from "./http-instance";

export default class ManageEventService {
  static async listCounties() {
    return get("Manage/ListCounties");
  }
  static async listEvents(data) {
    var url = `Manage/ListEvents?Status=${data.Status}&Page=${
      data.Page
    }&PageSize=${data.PageSize}&DNNUserId=${
      data.DNNUserId
    }&${data.OrganisationIds.map((id) => `OrganisationIds=${id}`).join("&")}`;
    //return get("Manage/ListEvents", data);
    return get(url);
  }
  static async getEventDetails(data) {
    return get("Manage/GetEventDetails", data);
  }
  static async saveEvent(data) {
    return post("Manage/SaveEvent", data);
  }

  static async deleteEvent(data) {
    return post("Manage/DeleteEvent", data);
  }

  static async listValidOrganisations() {
    return getFromWeb("Organisations/GetValidOrganisations");
  }
}
