import React, { useState, useEffect } from "react";
// import { Navbar } from "react-bootstrap";
const Navbar = React.lazy(() => import("react-bootstrap/Navbar"));
import { Link } from "react-router-dom";
import { CacheManager } from "../../Services/cache-service";
import logo from "../../Assets/img/Sailing_LIVE_Logo121x113.png";
import logoTEST from "../../Assets/img/Sailing_LIVE_Logo121x113TEST.png";
import MenuIcon from "./menu.icon.jsx";
import AppMenu from "./app-menu";
import StorageService from "../../Services/storage-service";

const MainMenu = ({
  pathname,
  onBackButton,
  showBackButon = true,
  ...props
}) => {
  const [backButton, setBackButton] = useState(false);
  const [lastUpdatedOn, setLastUpdatedOn] = useState("");
  const [flipMenuIcon, setFlipMenuIcon] = useState(false);
  const [cartItems, setCartItems] = useState(0);
  const [shouldRenderSearchButton, setShouldRenderSearchButton] =
    useState(false);

  const dontRenderSearchOn = ["/training", "/news"];

  useEffect(() => {
    setFlipMenuIcon(!flipMenuIcon);
    setBackButton(hasBackButton());
    return () => {};
  }, []);

  useEffect(() => {
    const url = window.location.href;

    if (dontRenderSearchOn.some((path) => url.includes(path))) {
      setShouldRenderSearchButton(false);
    } else {
      setShouldRenderSearchButton(true);
    }
  }, [window.location.href]);

  const hasBackButton = () => {
    // console.log(
    //   "hasBackButton",
    //   showBackButon,
    //   window.history,
    //   window?.history?.state?.idx !== 0
    // );
    let hasPreviousPage = window?.history?.length > 1;
    return hasPreviousPage ? showBackButon : false;
  };

  const clearSearch = () => {
    //StorageService.remove("search");
  };

  const loadHomePage = () => {
    cleanStorage();
    updateSw();
    //refreshCache();
  };

  const hostReachable = () => {
    var xhr = new (window.ActiveXObject || XMLHttpRequest)("Microsoft.XMLHTTP");
    xhr.open(
      "HEAD",
      "/?rand=" + Math.floor((1 + Math.random()) * 0x10000),
      false
    );
    try {
      xhr.send();
      return (xhr.status >= 200 && xhr.status < 300) || xhr.status === 304;
    } catch (error) {
      return false;
    }
  };

  const updateSw = () => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.getRegistration().then((reg) => {
        if (!reg) {
          return null;
        }

        reg.update();
      });
    }
  };

  const goBackHandler = () => {
    if (onBackButton) {
      onBackButton();
      return;
    }

    //get -1 history path
    window?.history?.length > 1 ? window.history.go(-1) : null;
  };

  const cleanStorage = () => {
    var sailingLiveItems = Object.keys(localStorage).filter(
      (key) =>
        key.startsWith("SAILINGLIVE_") && key !== "SAILINGLIVE_Event_Logos"
    );
    sailingLiveItems?.forEach((key) => {
      localStorage.removeItem(key);
    });
  };

  return (
    <>
      <Navbar
        bg="white"
        expand=""
        fixed="top"
        className="header-wrap d-flex justify-content-around position-relative col-12"
        id="top-navbar"
      >
        <div className="col-4 d-flex justify-content-start">
          {backButton && (
            <a
              className="navbar-toggler back-btn"
              onClick={goBackHandler}
              id="main-back-button"
            >
              <i className="fa fa-arrow-left"></i>
            </a>
          )}
        </div>
        <div className="col-4 d-flex justify-content-center">
          <Link to="/" className="logo-wrap" onClick={loadHomePage}>
            <img
              src={!process.env.REACT_APP_TEST ? logo : logoTEST}
              height="50px"
              className="align-center"
              alt="Sailing Live"
            />
          </Link>
        </div>
        <div className={`col-4 justify-content-end align-items-center d-flex`}>
          <Link
            to="/search"
            role="button"
            className={`navbar-toggler ${
              !shouldRenderSearchButton ? "d-none" : ""
            }`}
          >
            <i className="fas fa-search " />
          </Link>
          <AppMenu />
        </div>
      </Navbar>
    </>
  );
};

export default MainMenu;
